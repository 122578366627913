import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  useParams
} from "react-router-dom";
import { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga';
import C1Putting from "./components/C1Putting";
import C2Putting from "./components/C2Putting";
import { TrainingList } from "./pages/TrainingList";
import { About } from "./pages/About";
import { DGTNavbar } from "./components/DGTNavbar";
import { Footer } from "./components/DGTFooter";
import Home from "./pages/Home";
import { Alert, Container, Figure, Row } from "react-bootstrap";
import Amplify, { Auth, API } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import awsconfig from "./config/awsconfig.json";
import awsmobile from "./aws-exports";
import md5 from "md5";
import Profile from "./pages/Profile";
import FreePractice from "./components/FreePractice";
import Leaderboard from "./components/Leaderboard";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Fallout from "./components/Fallout";
import { Games } from "./pages/Games";
import ThreePutt from "./components/ThreePutt";
import { C1PuttingDesc, FalloutDesc, ThreePuttDesc, FreePracticeDesc } from "./components/TrainingsDesc";
import { Authenticator } from "./components/AuthContainer";

const env = process.env.REACT_APP_ENV || 'dev';
const awsconfiguration = awsconfig[env];
awsmobile.oauth.domain = env!=='prod'?'devauth.discgolf.training':'auth.discgolf.training';
awsmobile.oauth.redirectSignIn = `${window.location.origin}/`;
awsmobile.oauth.redirectSignOut = `${window.location.origin}/`;
Amplify.configure(awsmobile);
Auth.configure(awsmobile);

API.configure(awsconfiguration);

const LOGIN_URL = "https://" + awsmobile.oauth.domain + "/oauth2/authorize?" +
"response_type=" + awsmobile.oauth.responseType +
"&identity_provider=COGNITO" +
"&client_id=" + (window.location.host === 'android.discgolf.training' ? '728bar9l0e96ssh06ca5n7cfqi': awsmobile.aws_user_pools_web_client_id) +
"&redirect_uri=" + window.location.origin + "/" +
"&scope=" + awsmobile.oauth.scope.join("%20");


function DiscGolfTraining() {

  const [user, setUser] = useState();
  const [profile, setProfile] = useState()
  const [displayName, setDisplayName] = useState();
  const [authState, setAuthState] = useState();
  const [prepareShare, setPrepareShare] = useState(false);

  const [alert, setAlert] = useState({variant: "success", show: false, message:""})
  const shareRef = useRef(null);
  
  const getDisplayName = (user) => {
    const displayName = user && user.attributes && user.attributes.name?user.attributes.name:null;
    if (displayName) return displayName
    
    const firstName = (user && user.signInUserSession && user.signInUserSession.idToken.payload.given_name) || null;
    const lastName = (user && user.signInUserSession && user.signInUserSession.idToken.payload.family_name) || null;
    if (firstName && lastName) return lastName + ', ' + firstName
  
    return (user && user.signInUserSession && user.signInUserSession.idToken.payload.email) || (user && user.email)
  }

  const get_id = ((user) => {
    if (!user || !user.attributes) return null;
    Object.entries(user.attributes).map(([key, value]) => {
      if (key === 'email') {
        user.id = md5(value);
      }
      return null;
    })
  });


  useEffect(() => {  
    return onAuthUIStateChange((nextAuthState, authData) => {
      if (authData && !authData.id) {
        get_id(authData);
      }     
      setAuthState(nextAuthState);
      setUser(authData);
      !displayName && setDisplayName(getDisplayName(authData))    
    });
  }, []);

  useEffect(() => {
    if (user && user.signInUserSession && user.id) {
      let requestData = {
        headers: {Authorization: 'Bearer ' + user.signInUserSession.idToken.jwtToken}
      }
  
      API.get("DGTAPI", "/player/"+user.id, requestData)
      .then(response => {
        const attributes = ["name", "address", "pdga", "private_leaderboards"]
        let player_attributes = {}
        attributes.map((attribute) => {
          player_attributes[attribute] = response.attributes[attribute] || null
          return null;
        })
        setProfile(player_attributes)
      })
      .catch(error => {
        setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't get your profile."})
      })
    }
  }, [user])

  // useEffect(() => {
  //   Auth.currentAuthenticatedUser()
  //   .then(cognito_user => {
  //     Auth.currentSession().then(
  //       session => {
  //         cognito_user.Session=session;
  //         setUser({loggedIn: true, ...cognito_user});
  //       }
  //     );
  //   }).catch(err => {
  //     setUser({loggedIn: false})
  //   });
  // }, [])

  return (
    <Router>
      <div ref={shareRef}>
        {/* Status alert */}
        <Alert className="share-hidden" {...alert} onClose={() => setAlert({...alert, show: false}) }>
        {/* <ReactMarkdown children={alert.message} /> */}
        <span dangerouslySetInnerHTML={{ __html: alert.message }} />
        </Alert>
        <Authenticator />

        {/* Announcement alert
        <Alert {...announcement} className="mx-md-5 mx-2">
          <Alert.Heading>Title</Alert.Heading>
          <p className="mb-0">
            This is announcement text
          </p>
          <hr />
          <div className="d-flex justify-content-end align-items-center">
            <Form.Check type="checkbox" label="Don't show again" />&nbsp;
            <Button onClick={() => {setAnnouncement({...announcement, show: false});}} variant="outline-success">
              Close!
            </Button>
          </div>
        </Alert> */}
        <DGTNavbar user={user} authState={authState} displayName={displayName} LOGIN_URL={LOGIN_URL} profile={profile}/>
        {/* { prepareShare &&
            <Row className="text-center share-show">
              <Figure className="">
                <Figure.Image 
                  src="/ShareImage.webp"
                  className="align-middle"
                />
              </Figure>
            </Row>
          } */}
        
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/trainings" children={<Trainings user={user} authState={authState} setAlert={setAlert} profile={profile} LOGIN_URL={LOGIN_URL} shareRef={shareRef} prepareShare={prepareShare} setPrepareShare={setPrepareShare} />} />
          <Route path="/about" children={<About user={user} authState={authState} setAlert={setAlert} LOGIN_URL={LOGIN_URL}/>} />
          <Route path="/profile" children={authState === AuthState.SignedIn && user ? <Profile user={user} authState={authState} setAlert={setAlert} setDisplayName={setDisplayName}/> : <Home user={user} authState={authState} setAlert={setAlert} profile={profile} LOGIN_URL={LOGIN_URL}/>} />
          <Route path="/games" children={<><Games user={user} authState={authState} setAlert={setAlert} LOGIN_URL={LOGIN_URL}/><Footer/></>}/>
          <Route path="/termsofservice" children={<TermsOfService />} />
          <Route path="/privacypolicy" children={<PrivacyPolicy />} />
          <Route path="/" children={<Home user={user} authState={authState} setAlert={setAlert} profile={profile} LOGIN_URL={LOGIN_URL} shareRef={shareRef} prepareShare={prepareShare} setPrepareShare={setPrepareShare}/>}/>
        </Switch>
      </div>
    </Router>
  );
}

function Trainings(props) {
  let { path }  = useRouteMatch()
  return (
    <Switch>
      <Route exact path={path}>
        <Container>
          <Games {...props} />
          <TrainingList {...props} />
        </Container>
      </Route>
      <Route path={`${path}/:t_id/leaderboard`} render={(p) => (props.authState === AuthState.SignedIn && props.user ? <Leaderboard t_id={p.match.params.t_id} {...props}/> : <TrainingDescription {...props}/>)} />
      <Route path={`${path}/:t_id`} children={() => (props.authState === AuthState.SignedIn && props.user ? <Training {...props}/> : <TrainingDescription {...props} /> )} />
    </Switch>
  )
}

function Training(props) {
  let { t_id } = useParams();
  switch (t_id) {
    case "C1P":
      return (
        <C1Putting {...props} id={t_id}/>
      ) 
    case "C2P":
      return (
        <C2Putting {...props} id={t_id} />
      )  
    case "FP":
      return (
        <FreePractice {...props}/>
      )
    case "FLU":
      return (
        <Fallout {...props}/>
      )
    case "TPT":
      return (
        <ThreePutt {...props}/>
      )
    default:
      let [prefix, id] = t_id.split('-');
      if (prefix === "C1P") {
        return (
          <C1Putting {...props} id={id}/>
        )
      } else if (prefix === "FLU") {
        return (
          <Fallout {...props} id={id}/>
        )
      } else {
        return (
          <>
            <Trainings {...props}/>
            <Footer />
          </>
        )
      }   
  }
}

function getTrainingComponent(t_id) {
  switch(t_id) {
    case "C1P": 
      return C1PuttingDesc;
    case "FP":
      return FreePracticeDesc;
    case "FLU":
      return FalloutDesc;
    case "TPT":
      return ThreePuttDesc;
    default:
      return Trainings;   
  }
}

function TrainingDescription(props) {
  let { t_id } = useParams();
  let Component = getTrainingComponent(t_id);
  return (
    <>
      {/* <Authenticator /> */}
      <Component {...props}/>
      <Footer />
    </>
  )
}


export function useTitle(title) {
  const SITE_NAME = "Disc Golf Training"
  if (typeof title === 'function') title = title()  
  let pagetitle = title?title + " | " + SITE_NAME:SITE_NAME;
  let path = window.location.pathname + window.location.search

  useEffect(() => {
    const prevTitle = document.title
    

    if (pagetitle !== SITE_NAME) {
      ReactGA.pageview(path, null, pagetitle)
    }
    
    document.title = pagetitle;
    

    return () => {
      
      document.title = prevTitle
    }
  }, [pagetitle, path])
}

export default DiscGolfTraining;
