import { useState, useEffect } from "react";
import { API } from "aws-amplify";

import {Container, Row, Col, Form} from "react-bootstrap";
import { Pie, Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART_THEME, monochrom_colors } from "../static/constants";
import { useTitle } from "../DiscGolfTraining";
import qs from "qs";
import {useHistory, useLocation} from "react-router-dom";
import {PercentageTable} from "./PercentageTable";
import {StatisticsViewSwitch} from "./StatisticsViewSwitch";
import ShareResult from "./ShareResult";


export const Dashboard = ({prepareShare, setPrepareShare, ...props}) => {
  useTitle("Home");
  // const zerostats = {
  //   5: {hit: 0, total:0},
  //   6: {hit: 0, total:0},
  //   7: {hit: 0, total:0},
  //   8: {hit: 0, total:0},
  //   9: {hit: 0, total:0},
  //   10: {hit: 0, total:0},
  //   11: {hit: 0, total: 0 },
  //   12: {hit: 0, total: 0 },
  //   13: {hit: 0, total: 0 },
  //   14: {hit: 0, total: 0 },
  //   15: {hit: 0, total: 0 }
  // }

  const DASHBOARD_SETTINGS = 'dashboard_settings';
  const DEFAULT_DASHBOARD = {'view': 'pie'}
  const distances = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const zerostats = {};
    distances.forEach(dst => {
        zerostats[dst] = { hit: 0, total: 0 };
    });

  const { search } = useLocation();
  const history = useHistory();
  const [playerStats, setPlayerStats] = useState(zerostats)
  const [monthly, setMonthly] = useState({})
  const [dates, setDates] = useState ([])
  const [statsView, setStatsView] = useState(() =>{
    let savedData = DEFAULT_DASHBOARD
    
    let stored = localStorage.getItem(DASHBOARD_SETTINGS) 
    if (stored) {
      savedData = JSON.parse(Buffer.from(stored, "base64").toString("ascii"))[props.user.id] || {} 
    }
    
    return {...savedData}
  });

  const saveLocal = (view) => {
      localStorage.setItem(DASHBOARD_SETTINGS, Buffer.from(JSON.stringify({[props.user.id]: view})).toString("base64"))
  };

  const [filter, setFilter] = useState(() => {
    if (search) {
      return qs.parse(search, { ignoreQueryPrefix: true })
    }
    return {}
  })
  const totalPercentage = (distance) => {
    let c1_hits = 0;
    let c1_total = 0;
    let c2_hits = 0;
    let c2_total = 0;
    
    Object.entries(playerStats).map(([dst, stats]) => {
      if (dst<=10) {
        c1_hits += parseInt(stats.hit)
        c1_total += parseInt(stats.total)
      } else {
        c2_hits += parseInt(stats.hit)
        c2_total += parseInt(stats.total)
      }
      
    })
    return (
      <>
        <div>{'C1: ' + c1_hits + '/' + c1_total + ' (' + (c1_total>0?(100*(c1_hits/c1_total)).toFixed(2):0) + '%)'}</div>
        <div>{'C2: ' + c2_hits + '/' + c2_total + ' (' + (c2_total>0?(100*(c2_hits/c2_total)).toFixed(2):0) + '%)'}</div>
      </>
    )
  }
  let monthIterator = undefined

  useEffect(() => {
    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken}
    }
    if (Object.keys(filter).length > 0) requestData.queryStringParameters = filter
    API.get("DGTAPI", '/player/'+props.user.id+'/stats', requestData)
      .then(response => {
        const {total, detail, ...monthly} = response;
        setPlayerStats(total)
        if (filter && Object.keys(filter).length === 0) {
          setMonthly(monthly)
        }
      }).catch(() => {
        props.setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't fetch your statistics."})
      });
  }, [filter]);

  useEffect(() => {
    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken}
    }
    API.get("DGTAPI", '/player/'+props.user.id+'/dates', requestData)
      .then(response => {
        setDates(response)
      }).catch(() => {
      // props.setAlert({variant: "warning", show: true, dismissible: true, message: "Something went wrong. Couldn't load your training dates."})
    })
  }, [])

  const colors = monochrom_colors(CHART_THEME[0], 3);

  return (
    <Container className="my-2">
      <Row className="display-6 mb-2 share-hidden">
        Training Statistics
      </Row>
      <Row className="justify-content-between share-hidden">
        <Col xs="auto">
          <StatisticsViewSwitch statsView={statsView.view} setStatsView={setStatsView} saveLocal={saveLocal}/>
        </Col>
        {
          dates.length > 0 &&
            <Col xs="auto" className="me-4">
              {/*<Form.Label>Date</Form.Label>*/}
              <Form.Select
                aria-label="Select Date"
                name="year"
                value={filter.date}
                size="md"
                onChange={(e) => {
                  let val = e.target.value
                  let newFilter
                  if (val === "---") {
                    const { ["date"]: tmp, ...rest } = filter;
                    newFilter = rest
                    setFilter(rest)
                  } else {
                    newFilter = { ...filter, "date": val}
                    setFilter(newFilter)
                  }
                  history.replace(`/?${qs.stringify(newFilter)}`)
                }}
              >
                <option value="---" key='all'>All</option>
                {
                  // dates.sort((a, b) => {if (a<b) return 1; if (a>b) return -1; return 0}).map((d) => (
                  //   <option value={d} key={d}>{(new Date(d)).toLocaleDateString()}</option>
                  // ))

                  dates.sort((a, b) => {if (a<b) return 1; if (a>b) return -1; return 0}).map((d) => {
                    const date = new Date(d)
                    const yearMonth = d.slice(0,7)
                    if (monthIterator !== date.getUTCMonth()) {
                      monthIterator = date.getUTCMonth()
                      return  (
                        <>
                          <option value={yearMonth} key={yearMonth} className="">{'- ' + yearMonth + ' -'}</option>
                          <option value={d} key={d}>{d}</option>
                        </>
                      )
                    }
                    return <option value={d} key={d}>{d}</option>
                  })
                }
              </Form.Select>
            </Col>
        }
      </Row>
      {prepareShare && 
        <Container className="p-2">
          <Row className="text-center">
            <h2 className="display-4 text-white">My Stats</h2>
          </Row>
        </Container>
      }
      <Row className="h6 mt-2 fs-4  text-center share-hidden">
        <Col>
        <span>Totals</span>
          <ShareResult iconOnly={true} contentRef={props.shareRef} setPrepareShare={setPrepareShare} prepareShare={prepareShare} />
        </Col>
      </Row>
      <Row className="mb-2 d-block text-center text-muted fst-italic">
        {
          filter && Object.keys(filter).length === 0 ?
            "Lifetime totals"
            :
            // "Totals on: " + (new Date(filter.date)).toLocaleDateString()
            "Totals for: " + filter.date
        }
      </Row>
      <Row className="mb-2 d-block text-center text-muted fw-bold">
        {
          totalPercentage()
        }
      </Row>
      {
        statsView.view === 'table' ?
          <Row className="mb-4">
            <PercentageTable trainingProgress={playerStats} user={props.user} distances={distances}/>
          </Row>
          :
          <Row className="mb-4">
            {
              Object.entries(playerStats).map(([dst, stats]) => (
                <Col xs={6} md={4} key={dst} className="mb-3">
                  <Pie
                    data={{
                      labels: ["Hit", "Miss"],
                      datasets: [
                        {
                          label: {dst},
                          data: [parseInt(stats.hit), parseInt(stats.total) - parseInt(stats.hit)],
                          backgroundColor: CHART_THEME
                        }
                      ],
                    }}
                    options={{
                      maintainAspectRatio: false,
                      plugins: {
                        title: {display: true, text: `${dst}m/${(3.3*dst).toFixed(dst==='10'?0:1)}' | ${stats.total>0?(100*(stats.hit/stats.total)).toFixed(2):0}%` },
                        subtitle: {display: true, text: `(${stats.hit}/${stats.total})`},
                        datalabels: {
                          color: '#FFF',
                          anchor: 'center'
                        }
                      }
                    }}
                    plugins={[ChartDataLabels]}

                  />
                </Col>
              ))
            }
          </Row>

      }
      <Row className="h6 fs-4 mb-2 d-block text-center share-hidden">
        {
          monthly
          && Object.keys(monthly).length > 0
          && Object.getPrototypeOf(monthly) === Object.prototype 
          && "3-Month Trend (C1)"
        }
      </Row>
      <Row className="mb-4 d-none d-md-block share-hidden">
        {
          monthly
          && Object.keys(monthly).length > 0
          && Object.getPrototypeOf(monthly) === Object.prototype 
          && <Bar 
              data=
              {
                {
                  labels: ["5m / 16.5'", "6m / 19.8'", "7m / 23.1'", "8m / 26.4'", "9m / 29.7'", "10m / 33'"],
                  datasets:  
                    Object.entries(monthly).reverse().map(([month, pct], idx) => {
                      // console.log(month, pct)
                      return {
                        data: pct,
                        label: month,
                        backgroundColor: [...colors].reverse()[idx],
                        
                      }
                    })                  
                }    
              }
              height="100"
              options=
              {
                {
                  maintainAspectRatio: true,
                  scales: {
                    y: {
                      min: 0,
                      max: 100,
                      title: {
                        display: true,
                        text: '%',
                      }  
                    }
                  },
                  plugins: {
                      datalabels: {
                        color: function(context) {
                          return context.dataset.data[context.dataIndex] === 0 ? '#FFF' : '#404040';
                        },
                        font: {
                          weight: 'bold'
                        },
                        anchor: 'end',
                        align: 'start'
                    }
                  }
                }
              } 
              plugins={[ChartDataLabels]}
            />

        }
      
        
      </Row>
      <Row className="mb-4 d-md-none share-hidden">
        {
          monthly
          && Object.keys(monthly).length > 0
          && Object.getPrototypeOf(monthly) === Object.prototype 
          && <Bar 
              data=
              {
                {
                  labels: ["5m / 16.5'", "6m / 19.8'", "7m / 23.1'"],
                  datasets:  
                    Object.entries(monthly).reverse().map(([month, pct], idx) => {
                      let data = pct.slice(0,3)
                      return {
                        data: data,
                        label: month,
                        backgroundColor: [...colors].reverse()[idx],
                        
                      }
                    })                  
                }    
              }
              height="180px"
              options=
              {
                {
                  maintainAspectRatio: true,
                  scales: {
                    y: {
                      min: 0,
                      max: 100,
                      title: {
                        display: true,
                        text: '%',
                      }  
                    }
                  },
                  plugins: {
                    datalabels: {
                      display: function(context) {
                        return context.dataset.data[context.dataIndex] > 0;
                      },
                      // color: '#404040',
                      color: function(context) {
                        return context.dataset.data[context.dataIndex] === 0 ? '#FFF' : '#404040';
                      },
                      // backgroundColor: function(context) {
                      //   return [...colors][0];
                      // },
                      font: {
                        weight: 'bold'
                      },
                      anchor: 'end',
                      align: 'start'
                    }
                  }
                }
              } 
              plugins={[ChartDataLabels]}
            />

        }
      </Row>
      <Row className="mb-4 d-md-none share-hidden">
        {
          monthly
          && Object.keys(monthly).length > 0
          && Object.getPrototypeOf(monthly) === Object.prototype 
          && <Bar 
              data=
              {
                {
                  labels: ["8m / 26.4'", "9m / 29.7'", "10m / 33'"],
                  datasets:  
                    Object.entries(monthly).reverse().map(([month, pct], idx) => {
                      let data = pct.slice(3,6)
                      return {
                        data: data,
                        label: month,
                        backgroundColor: [...colors].reverse()[idx],
                        
                      }
                    })                  
                }    
              }
              height="160px"
              options=
              {
                {
                  maintainAspectRatio: true,
                  scales: {
                    y: {
                      min: 0,
                      max: 100,
                      title: {
                        display: true,
                        text: '%',
                      }  
                    }
                  },
                  plugins: {
                    datalabels: {
                      display: function(context) {
                        return context.dataset.data[context.dataIndex] > 0;
                      },
                      color: function(context) {
                        return context.dataset.data[context.dataIndex] === 0 ? '#FFF' : '#404040';
                      },
                      // backgroundColor: function(context) {
                      //   return [...colors][context.dataIndex];
                      // },
                      font: {
                        weight: 'bold'
                      },
                      anchor: 'end',
                      align: 'start'
                    },
                    legend: {
                      display: false
                    }
                  }
                }
              } 
              plugins={[ChartDataLabels]}
            />

        }
      </Row>
    </Container>
  )
}