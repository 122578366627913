import {ToggleButton, ButtonGroup} from "react-bootstrap";
import {Table, PieChart} from "react-bootstrap-icons"


export const StatisticsViewSwitch = (props) => {

  const views = [
    { name: 'Table', value: 'table', icon: <Table/> },
    { name: 'Pie', value: 'pie', icon:<PieChart/>},
  ];

  const changeView = ((view) => {
    props.saveLocal({view})
    props.setStatsView({view});
  })

  return (
      <ButtonGroup>
      {views.map((view, idx) => (
        <ToggleButton
          key={idx}
          id={`radio-${idx}`}
          type="radio"
          variant={props.statsView === view.value ? 'primary' : 'outline-primary'}
          name="view"
          value={view.value}
          checked={props.statsView === view.value}
          onChange={(e) => changeView(e.currentTarget.value)}
        >
          {view.icon}
        </ToggleButton>
      ))}
    </ButtonGroup>
  )
}