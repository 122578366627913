import { Navbar, Container, Row, Col, Figure, Button } from "react-bootstrap";
import { Facebook, Instagram } from "react-bootstrap-icons";

export const Footer = (() => {
  return (
    <Navbar bg="light" className="mt-5 pt-4 pb-3 share-hidden">
      <Container className="d-block">
        <Row className="mb-3 d-flex justify-content-center">
          <Col xs={2} className="d-md-none"/>
          <Col xs={2} md={2} lg={1} >
              
            <Figure className="">
              <Figure.Image 
                src="/logo192.webp"
                className=""
              />
            </Figure>
          </Col>
              
          <Col xs={6} sm={4} md={3} className="">
            <Row className="display-5 fs-5 d-block">Disc Golf Training</Row>
            <Row className="ps-0"><small>Built for you.</small></Row>
            <Row><small><a href="mailto:admin@discgolf.training?Subject=DGT Feedback" className="text-decoration-none">Provide Feedback</a></small></Row>
            <Row><small><a href="mailto:admin@discgolf.training?Subject=DGT Account Deletion" className="text-decoration-none">Delete Account</a></small></Row>
            <Row as="h6" className="d-block mt-3">Legal</Row>
            <Row>
              <small>
              <ul className="list-unstyled">
                <li><a href="/privacypolicy" className="text-decoration-none">Privacy Policy</a></li>
                <li><a href="/termsofservice" className="text-decoration-none">Terms of Service</a></li>
              </ul>
              </small>
            </Row>
          </Col>
          <Col xs={2} className="d-md-none"/>
  
          <Col xs={4} className="d-md-none"/>
          <Col xs={6} sm={4} md={3} lg={4} className="">
            <Row as="h6" className="d-block mt-2">Trainings</Row>
            <Row><a href="/games" className="text-decoration-none small">Games &amp; Rules</a></Row>
          </Col>
          <Col xs={2} className="d-md-none"/>
          {/* <Col md={2} className="text-md-start text-center">
            
          </Col> */}
          
          <Col md={2} className="text-center mt-md-0 mt-4">
            <Row as="h5" className="d-block mt-2">Community</Row>
            <Row className="mb-2"><Button variant="link" className="text-reset" href="https://www.facebook.com/discgolf.training" target="__blank"><Facebook size={32} /></Button></Row>
            <Row className="mb-2"><Button variant="link" className="text-reset" href="https://www.instagram.com/discgolf.training/" target="__blank"><Instagram size={32}></Instagram></Button></Row>
          </Col>
        </Row>
        <Row className="d-block text-center text-muted">© Disc Golf Training</Row>
      </Container>
    </Navbar>
  )
});
