import { Container, Row } from "react-bootstrap";
import { useTitle } from "../DiscGolfTraining";


export const Games = (props) => {  
  useTitle("Trainings");

  // const [trainings, setTrainings] = useState([]);

  // useEffect(() => {
  //   API.get("DGTAPI", '/training', {})
  //     .then(response => {
  //       setTrainings(response)
  //     }).catch(error => {
  //       console.log(error.response)
  //     });
  // }, [])


  return (
    <>
      <Container>
        <Row className="display-6">
          <h1 className="display-6">Trainings</h1>
        </Row>
        {/* <ul>
        {
          trainings.map((training) => (
            !training.draft && <li>{training.title}</li>
          ))
        }
        </ul> */}
        <ul><li>C1 Putting</li><li>C2 Putting</li><li>Fallout</li><li>Free Practice</li><li>Three putt</li></ul>

        <hr />
        <h2 className="py-2 text-primary">
          <a href="trainings/C1P" className="text-decoration-none">C1 Putting</a>
        </h2>
        <span className="badge bg-primary">5 discs, 6 markers</span>
        <p>Mark positions on 5m, 6m, 7m, 8m, 9m and 10m from the basket. The training consists of 20 rounds, each 5 putts.
After this training you'll make exactly 100 putts inside C1 (10m). Your starting position is on the 10m marker.
After throwing 5 discs, enter the number of made putts into the application and your next position will be
determined based on the following rule:</p>
        <ul>
        <li>0 hits -&gt; 5m</li>
        <li>1 hit -&gt; 6m</li>
        <li>2 hits -&gt; 7m</li>
        <li>3 hits -&gt; 8m</li>
        <li>4 hits -&gt; 9m</li>
        <li>5 hits -&gt; 10m</li>
        </ul>
        <p><strong>Scoring:</strong> For each round your score is &lt;# successful puts&gt;*&lt;distance coefficient&gt;.</p>
        <ul>
        <li>5m -&gt; 5pts</li>
        <li>6m -&gt; 6pts</li>
        <li>7m -&gt; 7pts</li>
        <li>8m -&gt; 8pts</li>
        <li>9m -&gt; 9pts</li>
        <li>10m -&gt; 10pts</li>
        </ul>

        <hr />
        <h2 className="py-2 text-primary">
          <a href="trainings/C2P" className="text-decoration-none">C2 Putting</a>
        </h2>
        <span className="badge bg-primary">5 discs, 6 markers</span>
        <p>Mark positions on 10m, 11m, 12m, 13m, 14m and 15m from the basket. The training consists of 10 rounds, each 5 putts.
        After this training you'll make exactly 50 putts from C2 (10-15m). Your starting position is on the 15m marker.
        After throwing 5 discs, enter the number of made putts into the application and your next position will be 
        determined based on the following rule:</p>
        <ul>
        <li>0 hits -&gt; 10m</li>
        <li>1 hit -&gt; 11m</li>
        <li>2 hits -&gt; 12m</li>
        <li>3 hits -&gt; 13m</li>
        <li>4 hits -&gt; 14m</li>
        <li>5 hits -&gt; 15m</li>
        </ul>
        <p><strong>Scoring:</strong> For each round your score is &lt;# successful puts&gt;*&lt;distance coefficient&gt;.</p>
        <ul>
        <li>10m -&gt; 10pts</li>
        <li>11m -&gt; 11pts</li>
        <li>12m -&gt; 12pts</li>
        <li>13m -&gt; 13pts</li>
        <li>14m -&gt; 14pts</li>
        <li>15m -&gt; 15pts</li>
        </ul>

        <hr />
        <h2 className="py-2 text-primary">
          <a href="trainings/FLU" className="text-decoration-none">Fallout</a>
        </h2>
        <span className="badge bg-primary">5 discs, 6 markers</span>
        <p>Mark positions on 5m, 6m, 7m, 8m, 9m and 10m from the basket. Start on 5m marker with 5 discs. Go to the next position only with the discs you made putts.</p>
        <p><strong>Scoring:</strong> Each distance has a distance coefficient which is multiplied by the number of made putts:</p>
        <ul>
        <li>5m, 6m -&gt; 1pt</li>
        <li>7m, 8m -&gt; 2pts</li>
        <li>9m, 10m  -&gt; 3pts</li>
        </ul>
        
        <hr />
        <h2 className="py-2 text-primary">
          <a href="trainings/TPT" className="text-decoration-none">Three putt</a>
        </h2>
        <span className="badge bg-primary">3 discs, 6 markers</span><p>Mark positions on 5m, 6m, 7m, 8m, 9m and 10m from the basket. Start on 5m marker with 3 discs. If you make:</p>
        <ul>
        <li>3 putts - move one marker back</li>
        <li>2 putts - stay on the same marker</li>
        <li>1 putt - move one marker closer to the basket</li>
        <li>0 putts - go to the 5m marker.</li>
        </ul>
        <p>Can you get to the last marker?</p>

        <hr />
        <h2 className="py-2 text-primary">
          <a href="trainings/FP" className="text-decoration-none">Free Practice</a>
        </h2>
        <span className="badge bg-primary">1-15 discs, 1 or more markers</span>
        <p>Free training. Select number of discs and distance from the dropdowns and just throw. Enter the number of <strong>made putts</strong> into the application and continue from the same distance, or pick a new distance from the dropdown.</p>
        <p>After you're done, save your game.</p>
        
        
        {/* {
          trainings.map((training) => (
            
            !training.draft &&
              <>
                <hr/>
                <h2 className="py-2 text-primary"><a href={"trainings/"+training.t_id} className="text-decoration-none">{training.title}</a></h2> 
                <Badge bg="primary">{training.equipment_desc}</Badge>
                <ReactMarkdown children={training.description} />
              </>
            
            
          
          ))
        } */}
      </Container>
    </>
  )
}