import { Modal, Button } from "react-bootstrap"

export const ConfirmationModal = (props) => {
  
  return (
    <Modal
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.body}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Cancel
          </Button>
          <Button variant={props.variant || "primary"} onClick={props.handleConfirmation}>{props.buttonText || "Ok"}</Button>
        </Modal.Footer>
      </Modal>
  )
}

