import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";
import {API} from "aws-amplify";
import {ArrowDownShort, ArrowUpShort, Dash} from "react-bootstrap-icons";

export const PercentageTable = (props) => {

    // const zerostats = {
    //     5: {hit: 0, total:0},
    //     6: {hit: 0, total:0},
    //     7: {hit: 0, total:0},
    //     8: {hit: 0, total:0},
    //     9: {hit: 0, total:0},
    //     10: {hit: 0, total:0},
    // }

    // const [monthly, setMonthly] = useState({})
    const [displayTotals, setDisplayTotals] = useState(false)
    const distances = props.distances || [5, 6, 7, 8, 9, 10]

    const zerostats = {};
    distances.forEach(dst => {
        zerostats[dst] = { hit: 0, total: 0 };
    });

    const [playerStats, setPlayerStats] = useState(zerostats)

    const calculatePercentage = (made, tot) => {
        if (tot>0) {
            return (100*made/tot).toFixed(2)
        }
        return 0
    }
    const percentageIndicator = (dst) => {
        if (props.trainingProgress[dst].total === 0) {
            return (
                <Dash color="#D4AC0D" />
            )
        }
        if (parseFloat(calculatePercentage(props.trainingProgress[dst].hit, props.trainingProgress[dst].total)) > parseFloat(calculatePercentage(playerStats[dst].hit, playerStats[dst].total))) {
            return (
                <ArrowUpShort color="#27AE60" />
            )
        } else if (parseFloat(calculatePercentage(props.trainingProgress[dst].hit, props.trainingProgress[dst].total)) < parseFloat(calculatePercentage(playerStats[dst].hit, playerStats[dst].total))) {
            return (
                <ArrowDownShort color="#E74C3C" />
            )
        } else {
            return (
              <Dash color="#D4AC0D" />
            )
        }
    }
    const toggleDisplayTotals = () => {
        setDisplayTotals(!displayTotals)
    }

    useEffect(() => {
        let requestData = {
            headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken}
        }
        API.get("DGTAPI", '/player/'+props.user.id+'/stats', requestData)
        .then(response => {
            if ('total' in response) {
                const {total, ...monthly} = response;
                setPlayerStats(total)
                // setMonthly(monthly)
            }
        }).catch(error => {
            console.log(error)
        });
    }, [])

    return (
        <Container onClick={toggleDisplayTotals}>
            <Row className="my-2 text-center fw-bold">
                <Col>Distance</Col>
                <Col>Putting</Col>
                <Col>Percentage</Col>
            </Row>
            { distances.map((dst) => (
                <Row className="my-1 text-center border-top" key={dst}>
                    <Col>
                        <div>{dst}&nbsp;m&nbsp;/&nbsp;{(3.3*dst).toFixed(dst===10?0:1)}'</div>
                        {/*<div className={displayTotals?'text-muted':'d-none'}>Past trainings:</div>*/}
                    </Col>
                    <Col>
                        <div>{props.trainingProgress[dst].hit}/{props.trainingProgress[dst].total}</div>
                        <div className={displayTotals?'text-muted fw-light small':'d-none'}>{playerStats[dst].hit}/{playerStats[dst].total}</div>
                    </Col>
                    <Col>
                        <div>
                            <span>
                                {calculatePercentage(props.trainingProgress[dst].hit, props.trainingProgress[dst].total)}&nbsp;%
                            </span>
                            <span>
                                {percentageIndicator(dst)}
                            </span>
                        </div>
                        <div className={displayTotals?'text-muted fw-light small':'d-none'}>
                            {calculatePercentage(playerStats[dst].hit, playerStats[dst].total)}&nbsp;%
                        </div>
                    </Col>
                </Row>
            ))}
        </Container>
    )
}