import { useState, useEffect } from "react";
import { BASIC_STATS, FP_MAX_DISCS } from "../static/constants";
import { Container, Row, Col, Accordion, Form, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { API } from "aws-amplify";
import {PercentageTable} from "./PercentageTable";
import ShareResult from "./ShareResult";

const FreePractice = ({prepareShare, setPrepareShare, ...props}) => {

  const TRAINING_ID = "FP";
  const LOCALSTORAGE_PROGRESS = "FPProgress";
  const distances = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const [trainingProgress, settrainingProgress] = useState(() => {
    let savedData = {}

    let stored = localStorage.getItem(LOCALSTORAGE_PROGRESS) 
    if (stored) {
      savedData = JSON.parse(Buffer.from(stored, "base64").toString("ascii"))[props.user.id] || {} 
    }

    return {...BASIC_STATS, round: 1, ...savedData}
  });
  const [animate, setAnimate] = useState(false);
  const [discs, setDiscs] = useState(5);
  const [distance, setDistance] = useState(5)
  const [trainingDetails, setTrainingDetails] = useState({description: "", equipment: ""});

  const saveLocal = (progress) => {
    progress?
      localStorage.setItem(LOCALSTORAGE_PROGRESS, Buffer.from(JSON.stringify({[props.user.id]: progress})).toString("base64"))
      :
      localStorage.removeItem(LOCALSTORAGE_PROGRESS)
  };

  const submitGame = () => {
    // alert("Submitting game...")
    let trainingResult = JSON.parse(JSON.stringify(trainingProgress))
    delete trainingResult._lastRound
    delete trainingResult.next
    delete trainingResult.round
    delete trainingResult.training_i


    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken},
      body: {...trainingResult}
    }

    API.post("DGTAPI", "/training/"+TRAINING_ID+"/"+trainingProgress.training_i, requestData)
      .then(() => {
        saveLocal(null)
        settrainingProgress({...BASIC_STATS, round: 1})
        props.setAlert({variant: "success", show: true, dismissible: true, message: "Training successfully saved!"})
      }).catch(() => {
        props.setAlert({
          variant: "danger",
          show: true,
          dismissible: true,
          message: "Something went wrong. Refresh the page and save again, your data won't be lost."
        })
      })
  };

  const submitRound = ((hit) => {
    let currentProgress = JSON.parse(JSON.stringify(trainingProgress))
    currentProgress["_lastRound"] = JSON.parse(JSON.stringify(trainingProgress))
    currentProgress["round"] = trainingProgress.round+1

    currentProgress[distance]["hit"] = trainingProgress[distance]["hit"] + parseInt(hit)
    currentProgress[distance]["total"] = trainingProgress[distance]["total"] + discs

    saveLocal(currentProgress);
    settrainingProgress(currentProgress);
    setAnimate(true)
  });

  const undoLast = () => {
    saveLocal(trainingProgress._lastRound)
    settrainingProgress(trainingProgress._lastRound)
  };

  useEffect(() => {
    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken}
    }

    if (!("training_i" in trainingProgress)) {  
      API.post("DGTAPI", "/training/"+TRAINING_ID, requestData)
        .then(response => {
          settrainingProgress({training_i: response.training_i, ...trainingProgress})
          setTrainingDetails(response.attributes)
          saveLocal({training_i: response.training_i, ...trainingProgress});
        }).catch(() => {
          props.setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't initialize your training."})
        });
    } else {
      if (!trainingDetails.description || trainingDetails.description === ""){
        API.get("DGTAPI", "/training/"+TRAINING_ID, requestData)
        .then(response => {
          setTrainingDetails(response.attributes)
        }).catch(() => {
          props.setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't initialize your training."})
        });
      }
    }
    
  }, [trainingProgress.training_i])

  return (
    <Container className="p-2">
      {prepareShare && 
        <Row className="text-center">
          <h2 className="display-4 text-white">Free Practice</h2>
        </Row>
      }
      <Row className="share-hidden">
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <div className="d-grid gap-1">
                <h1 className="h3">Free Practice <small className="fs-6 fst-italic fw-light">Tap to expand the rules</small></h1>
                { trainingDetails.equipment && trainingDetails.equipment_desc && <small><abbr title={trainingDetails.equipment_desc}>{trainingDetails.equipment}</abbr></small> }
              </div>
            </Accordion.Header>
            <Accordion.Body>
              <ReactMarkdown children={trainingDetails.description} />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Row>
      <Row className="my-2 text-center fw-bold share-hidden">
        <Col xs={4}>
          Round:
        </Col>
        <Col xs={4}>
          # of discs:
        </Col>
        <Col xs={4}>
          Distance:
        </Col>
      </Row>
      <Row className="my-2 text-center share-hidden">
        <Col className={animate?'fadeIn':''} onAnimationEnd={() => setAnimate(false)}>
          #{trainingProgress.round}
        </Col>
        <Col>
          <Form.Select value={discs} className="d-inline w-auto" onChange={(e) => setDiscs(parseInt(e.target.value))} >
            {
              [...Array(FP_MAX_DISCS)].map((e, i) => (
                <option value={i+1}>{i+1}</option>
              ))
            }
             {discs}
          </Form.Select>
        </Col>
        <Col>
          <Form.Select value={distance} className="d-inline w-auto" onChange={(e) => setDistance(e.target.value)}>
          { 
            distances.map((dst) => (
              <option value={dst}>{dst}&nbsp;m&nbsp;/&nbsp;{(3.3*dst).toFixed(dst===10?0:1)}'</option>
            ))
          }
          </Form.Select>
        </Col>
      </Row>
      <Row className="share-hidden">
        <hr />
      </Row>
      <Row className="share-hidden">
        <Col className="text-muted text-center">Putts hit:</Col>
      </Row>
      <Row xs={4} md={8} className="share-hidden">
        { [...Array(discs+1)].map((e, i) => (
          <Col className="" key={i}>
            <div className="d-grid gap-1">
              <Button variant="primary" key={i} className="mx-1 my-2" value={i} onClick={(e) => submitRound(e.target.value)}>{i}</Button>
            </div>
          </Col>
        ))}
      </Row>
      <Row className="mt-3">
        <PercentageTable trainingProgress={trainingProgress} distances={distances} user={props.user}/>
      </Row>
      <Row className="mt-3 share-hidden">
        <Col xs={6} className="">
          <div className="d-grid gap-1">
            <Button variant="success" className="" disabled={!trainingProgress._lastRound} onClick={() => submitGame()}>Save Game</Button>
          </div>
        </Col>
        <Col xs={6}>
          <div className="d-grid gap-1">
            <Button variant="secondary" className="" disabled={!trainingProgress._lastRound} onClick={() => undoLast()}>Undo Last</Button>
          </div>
        </Col>
      </Row>
      <Row className="mt-2 share-hidden">
        <Col xs={12} sm={6} className="d-grid mx-n2">
          <ShareResult className="m-6 " contentRef={props.shareRef} setPrepareShare={setPrepareShare} prepareShare={prepareShare}/>
        </Col>
      </Row>
    </Container>
  )

}

export default FreePractice;