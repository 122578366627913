import { Carousel, Figure, Row, Col, Button } from "react-bootstrap";
import { AuthState } from '@aws-amplify/ui-components';
import { 
  FacebookShareButton, FacebookIcon, 
  TwitterShareButton, TwitterIcon, 
  RedditShareButton, RedditIcon, 
  PinterestShareButton, PinterestIcon,
  FacebookMessengerShareButton, FacebookMessengerIcon,
  TelegramShareButton, TelegramIcon, 
  WhatsappShareButton, WhatsappIcon,
  ViberShareButton, ViberIcon, 

} from "react-share";

export const WhatDGT = ((props) => {

  return (
    <Row className={`py-4 ${props.className?props.className:''}`}>
      <Col md={6} lg={9}>
        <h1 className="display-5"><small className="text-muted">What is</small> Disc Golf Training?</h1>
        <p className="fs-4">All-in-one. For free.</p>
        <p className="fs-5 text-justify">Disc Golf Training is an <i>all-in-one</i> training app for disc golfers. It offers <a href="/games">multiple training drills</a>, fun games and world-wide leaderboards.
          With this app you can train your C1 putts, have insight to statistics, and monitor your progress through the time.</p>
        <div className="d-md-none mb-2"><Button variant="primary" className="d-block" href={props.LOGIN_URL}>Sign in</Button></div>
        <p className="fs-6 text-justify">
          Disc Golf Training is a responsive browser-based application (no download required), created for all screen types: 
          from <strong>mobile phones</strong> and <strong>tablet</strong> devices to <strong>PC's</strong> and <strong>laptops</strong>.
          Add <a href="https://discgolf.training">https://discgolf.training/</a> to your bookmarks and start your putting drills.
        </p>
        
        <p className="fs-6">Disc Golf Training is completely free.&nbsp;
        {props.authState === AuthState.SignedIn && props.user?
          "Choose your training"
        :
          <a href={props.LOGIN_URL} ><strong>Sign in/Sign up</strong></a> 
        }
        &nbsp;and start throwing them discs into the basket.
        </p>
      </Col>
      <Col md={6} lg={3} className="d-xs-none d-flex align-items-center justify-content-center d-print-none">
        <Figure className="align-middle">
          <Figure.Image 
            src="/logo192.webp"
            className="align-middle"
          />
        </Figure>
      </Col>
    </Row>
  )
});

export const WhyDGT = ((props) => {
  return (
    <div className={`${props.className?props.className:''}`}>
      <h1 className="display-5"><small className="text-muted">Why</small> Disc Golf Training?</h1>
        <p className="fs-4">Everybody needs C1x putts.</p>
        <p className="fs-5">Play fun putting games, compete with your friends, track your progress...</p>
        <ul className="d-block text-start pt-md-3">
          <li className="fs-5">Multiple putting drills</li>
          <li className="fs-5">Putting statistics</li>
          <li className="fs-5">Monthly trends</li>
          <li className="fs-5">Leaderboards</li>
          <li className="fst-italic">more to come...</li>
        </ul>
      {props.authState !== AuthState.SignedIn &&
        <Row className="justify-content-center mb-3">
          <Col xs={6} sm={4} md={3} className="d-grid ">
            <Button variant="outline-primary" className="d-block" href={props.LOGIN_URL}>Sign in</Button>
          </Col>
        </Row>
      }
    </div>
  )
});

export const Testimonials = (() => {

  return (
    <Carousel variant="dark" className="bg-light py-3 d-print-none">
      <Carousel.Item className="text-center">
        <Figure className="m-5 text-center" >
          <blockquote className="blockquote">
            <p>This app helped me so much.</p>
          </blockquote>
          <Figure.Caption className="blockquote-footer">
            Noone Ever <cite title="Source Title">(PDGA #999999)</cite>
          </Figure.Caption>
        </Figure>
      </Carousel.Item>      
      <Carousel.Item className="text-center">
        <Figure className="m-5 text-center">
          <blockquote className="blockquote">
            <p>Didn't know how low my percentages were. Now I do! :D</p>
          </blockquote>
          <Figure.Caption className="blockquote-footer">
            Noodle Arm <cite title="Source Title">(PDGA #00000)</cite>
          </Figure.Caption>
        </Figure>
      </Carousel.Item>     
    </Carousel>
  )
});

export const Features = ((props) => {

  return (
    <Row className="py-5 d-flex flex-row-reverse">
      <Col lg={7}>
        <WhyDGT className="text-end" authState={props.authState} LOGIN_URL={props.LOGIN_URL}/>
      </Col>
      <Col lg={5}>
        <Carousel variant="dark" interval={2500} className="bg-primary py-3 d-print-none d-block mh-100 ">
          <Carousel.Item className="text-center">
            <img
              className=""
              style={{"height": "30rem"}}
              src="/C1xPutting.webp"
              alt="C1x Putting game"
            />
          </Carousel.Item>      
          <Carousel.Item className="text-center">
            <img
              className=""
              style={{"height": "30rem"}}
              src="/Stats.webp"
              alt="Statistics"
            />
          </Carousel.Item>
          <Carousel.Item className="text-center">
            <img
              className=""
              style={{"height": "30rem"}}
              src="/FreePractice.webp"
              alt="Free Practice game"
            />
          </Carousel.Item>    
          <Carousel.Item className="text-center">
            <img
              className=""
              style={{"height": "30rem"}}
              src="/Leaderboard.webp"
              alt="Leaderboard"
            />
          </Carousel.Item>    
        </Carousel>
      </Col>
      
    </Row>
  )
});

export const WhoDGT = ((props) => {
  return (
    <div className={`py-4 ${props.className?props.className:''}`}>
      <h1 className="display-5"><small className="text-muted">Who is </small>Disc Golf Training<small className="text-muted"> for?</small></h1>
      <p className="fs-4">Me. You. Everybody.</p>
      <p className="fs-5">
        Disc Golf Training is designed for training inside circle one putts. C1x putts are the crucial part of the game, where everybody can improve.
      </p>
      <p className="fs-6">
        Disc Golf Training can be equaly used by the <strong>novice</strong> players, and more <strong>advanced</strong> and <strong>professional</strong> disc golfers.
      </p>
      <p className="fs-5 text-center">If you want to improve your putting - Disc Golf Training is for you!</p>
      <Row className="justify-content-center mb-3">
        <Col sm={12} md={6} lg={4} className="d-grid ">
          <Button variant="outline-primary" className="d-block" href="/games">Check available trainings</Button>
        </Col>
      </Row>
      {/* <div className="mb-2"><Button variant="primary" className="d-block" href="/trainings"></Button></div> */}
    </div>
  )
})

export const ShareSocial = ((props) => {
  const title = "Disc Golf Training"
  const shareUrl = window.origin
  return (
    <div className={props.className}>
    <FacebookShareButton
      url={shareUrl}
      quote={title}
      className="me-1"
    >
      <FacebookIcon size={32} round />
    </FacebookShareButton>
    
    <TwitterShareButton
      url={shareUrl}
      title={title}
      className="me-1"
      >
      <TwitterIcon size={32} round />
    </TwitterShareButton>  
    
    <RedditShareButton
      url={shareUrl}
      title={title}  
      className="me-1"
    >
      <RedditIcon size={32} round/>
    </RedditShareButton>

    <PinterestShareButton
      url={shareUrl}
      title={title}  
      media="/logo512.png"
    >
      <PinterestIcon size={32} round/>
    </PinterestShareButton>
    </div>
  )
})

export const ShareMessage = ((props) => {
  const title = "Disc Golf Training"
  const shareUrl = window.origin

  return (
    <div className={props.className}>
      <FacebookMessengerShareButton
      url={shareUrl}
      redirectUri={shareUrl}
      title={title}
      appId="254567746675078"
      className="me-1"
    >
      <FacebookMessengerIcon size={32} round />
    </FacebookMessengerShareButton>

    <TelegramShareButton
      url={shareUrl}
      title={title}
      className="me-1"
    >
      <TelegramIcon size={32} round />
    </TelegramShareButton>

    <WhatsappShareButton 
      url={shareUrl}
      title={title}
      separator=":: "
      className="me-1"
    >
      <WhatsappIcon size={32} round />
    </WhatsappShareButton>

    <ViberShareButton 
      url={shareUrl}
      title={title}
      separator=" "
      className="me-1"
    >
      <ViberIcon size={32} round />
    </ViberShareButton>
    </div>
  )
})