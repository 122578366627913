import { useState, useEffect } from "react";
import { Button, Container, Row, Col, Accordion, ProgressBar } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { BASIC_STATS } from "../static/constants";
import { API } from "aws-amplify";
import {PercentageTable} from "./PercentageTable";
import ShareResult from "./ShareResult";

const ThreePutt = ({prepareShare, setPrepareShare, ...props}) => {
  const TRAINING_ID = "TPT";
  const LOCALSTORAGE_PROGRESS = "TPTProgress";

  const distances = [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
  const hits = [0, 1, 2, 3];
  const MAX_DISTANCE = 15;

  const [trainingProgress, settrainingProgress] = useState(() =>{
    let savedData = {}
    
    let stored = localStorage.getItem(LOCALSTORAGE_PROGRESS) 
    if (stored) {
      savedData = JSON.parse(Buffer.from(stored, "base64").toString("ascii"))[props.user.id] || {} 
    }
    
    return {...BASIC_STATS, round: 1, rounds: [], next: 5,  ...savedData}
  });

  const [trainingDetails, setTrainingDetails] = useState({description: "", equipment: ""});
  const [animate, setAnimate] = useState(false);

  const saveLocal = (progress) => {
    progress?
      localStorage.setItem(LOCALSTORAGE_PROGRESS, Buffer.from(JSON.stringify({[props.user.id]: progress})).toString("base64"))
      :
      localStorage.removeItem(LOCALSTORAGE_PROGRESS)
  };

  useEffect(() => {
    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken}
    }

    if (!("training_i" in trainingProgress)) {  
      API.post("DGTAPI", "/training/"+TRAINING_ID, requestData)
        .then(response => {
          settrainingProgress({training_i: response.training_i, ...trainingProgress})
          setTrainingDetails(response.attributes)
          saveLocal({training_i: response.training_i, ...trainingProgress});
        }).catch(() => {
          props.setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't initialize your training."})
        });
    } else {
      if (!trainingDetails.description || trainingDetails.description === ""){
        API.get("DGTAPI", "/training/"+TRAINING_ID, requestData)
        .then(response => {
          setTrainingDetails(response.attributes)
        }).catch(() => {
          props.setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't initialize your training."})
        });
      }
    }
    
  }, [trainingProgress.training_i])

  const submitGame = () => {
    // alert("Submitting game...")
    let trainingResult = JSON.parse(JSON.stringify(trainingProgress))
    delete trainingResult._lastRound
    delete trainingResult.next
    delete trainingResult.round
    delete trainingResult.training_i


    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken},
      body: {...trainingResult}
    }

    API.post("DGTAPI", "/training/"+TRAINING_ID+"/"+trainingProgress.training_i, requestData)
      .then(() => {
        saveLocal(null)
        settrainingProgress({...BASIC_STATS, round: 1, rounds: [], next: 5})
        props.setAlert({variant: "success", show: true, dismissible: true, message: "Training successfully saved!"})
      }).catch(() => {
        props.setAlert({
          variant: "danger",
          show: true,
          dismissible: true,
          message: "Something went wrong. Refresh the page and save again, your data won't be lost."
        })
      })
  };

  const submitRound = (hit) => {
    let currentProgress = JSON.parse(JSON.stringify(trainingProgress))
    currentProgress["_lastRound"] = JSON.parse(JSON.stringify(trainingProgress))

    currentProgress["round"] = trainingProgress.round+1
    currentProgress["rounds"].push(hit)

    switch (parseInt(hit)) {
      case 0:
        currentProgress["next"] = distances[0]
        break;
      case 1:
        if (currentProgress["next"] > 5)
          currentProgress["next"] -= 1
        else
          currentProgress["next"] = distances[0]
        break;;
      case 3:
        if (currentProgress["next"] < MAX_DISTANCE)
          currentProgress["next"] += 1
        else
          currentProgress["next"] = distances[distances.length-1]
        break;
      default:
        break;
    }

    currentProgress[trainingProgress["next"]]["hit"] = trainingProgress[trainingProgress["next"]]["hit"] + parseInt(hit)
    currentProgress[trainingProgress["next"]]["total"] = trainingProgress[trainingProgress["next"]]["total"] + 3

    saveLocal(currentProgress)
    settrainingProgress(currentProgress)
    setAnimate(true)
      
  };

  const undoLast = () => {
    saveLocal(trainingProgress._lastRound)
    settrainingProgress(trainingProgress._lastRound)
  };

  return (
    <div>
      <Container className="p-2">
        <Container>
          {prepareShare && 
            <Row className="text-center">
              <h2 className="display-4 text-white">Three Putt</h2>
            </Row>
          }
          <Row className="share-hidden">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="d-grid gap-1">
                    <h1 className="h3">Three Putt <small className="fs-6 fst-italic fw-light">Tap to expand the rules</small></h1>
                    { trainingDetails.equipment && trainingDetails.equipment_desc && <small><abbr title={trainingDetails.equipment_desc}>{trainingDetails.equipment}</abbr></small> }
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ReactMarkdown children={trainingDetails.description} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
          <Row className="my-2 text-center fw-bold share-hidden">
            <Col xs={4}>
              Round:
            </Col>
            {/* <Col xs={4} /> */}
            <Col xs={4}>
              Position:
            </Col>
            
          </Row>
          <Row className="my-2 text-center align-items-center share-hidden">
            <Col xs={4} className={animate?'fadeIn':''} onAnimationEnd={() => setAnimate(false)}> 
              #{trainingProgress.round}
            </Col>
            {/* <Col xs={4} /> */}
            <Col xs={4} className={animate?'fadeIn fw-bolder fs-5':'fw-bolder fs-5'} onAnimationEnd={() => setAnimate(false)}>
              {trainingProgress.next}&nbsp;m&nbsp;/&nbsp;{(3.3*trainingProgress.next).toFixed(trainingProgress.next===10?0:1)}'
            </Col>
          
          </Row>
        </Container>
        <Row className="my-3 share-hidden">
            <ProgressBar now={trainingProgress.next} min={5} max={10} className="p-0" style={{height: "2px"}}/>
        </Row>
        <Row className="share-hidden">
          <Col className="text-muted text-center">Putts hit:</Col>
        </Row>
        <Row className="share-hidden">
          { hits.map((hit) => (
            <Col lg={3}  xs={3} className="">
              <div className="d-grid gap-1">
                <Button variant="primary" key={hit} className="mx-1 my-2" value={hit} onClick={(e) => submitRound(e.target.value)}>{hit}</Button>
              </div>
            </Col>
          ))}
        </Row>
        
        <Row className="mt-3">
          <PercentageTable trainingProgress={trainingProgress} distances={distances} user={props.user}/>
        </Row>
        <Row className="mt-3 share-hidden">
          <Col xs={6} className="">
            <div className="d-grid gap-1">
            <Button variant="success" className="" disabled={!trainingProgress._lastRound} onClick={() => submitGame()}>Save Game</Button>
            </div>
          </Col>
          <Col xs={6}>
            <div className="d-grid gap-1">
              <Button variant="secondary" className="" disabled={!trainingProgress._lastRound} onClick={() => undoLast()}>Undo Last</Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-2 share-hidden">
          <Col xs={12} sm={6} className="d-grid mx-n2">
            <ShareResult contentRef={props.shareRef} setPrepareShare={setPrepareShare} prepareShare={prepareShare}/>
          </Col>
      </Row>
      </Container>
    </div>
  )
}

export default ThreePutt