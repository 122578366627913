import { Container, Spinner } from "react-bootstrap"
import { Dashboard } from "../components/Dashboard";
import { TrainingList } from "./TrainingList";
import { About } from "./About";
import { AuthState } from "@aws-amplify/ui-components";
import ShareCanvas from "../components/ShareCanvas";
import { Footer } from "../components/DGTFooter";


export const Home = (props) => {
  
  return (
    // <Container>
    //   {
    //   props.user.loggedIn? 
    //     <>
    //     <Dashboard user={props.user} />
    //     <TrainingList user={props.user} />
    //     </>
    //   :
    //     props.user.loggedIn === null? // waiting for state to load
    //       <div className="m-5 text-center">
    //         <Spinner animation="grow" variant="primary" role="status">
    //           <span className="sr-only"></span>
    //         </Spinner>
    //       </div>
    //     :
    //       <Button variant="primary" href="">Login</Button>
    //   } 
    // </Container>
      <>
      {
      props.authState === AuthState.SignedIn && props.user ?
        <>
          <Container>
            {/* <ShareCanvas /> */}
            <Dashboard {...props} />
            <TrainingList {...props} className="share-hidden"/>
          </Container>   
          <Footer className="share-hidden"/>
        </>
      :
        <>
          {
            props.authState === AuthState.SignIn?
              <About LOGIN_URL={props.LOGIN_URL}/>
            :
              <div className="text-center">
                <Spinner animation="border" variant="primary" role="status">
                  <span className="sr-only"></span>
                </Spinner>
              </div>
          }
        </>
      }
      </>
  )
}
export default Home;