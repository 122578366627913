import { AmplifyAuthenticator, AmplifyAuthContainer } from '@aws-amplify/ui-react';

export const Authenticator = () => {
  return (
    <AmplifyAuthContainer className="d-none">
      <AmplifyAuthenticator>
        <div slot="sign-in">
        </div>            
        
      </AmplifyAuthenticator>
    </AmplifyAuthContainer>
  )
}