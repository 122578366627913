import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import html2canvas from 'html2canvas';

const ShareResult = ({iconOnly, contentRef, setPrepareShare, prepareShare}) => {

  const formatDateTimeToYYYYMMDDHHMMSS = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}${month}${day}${hours}${minutes}${seconds}`;
  }

  const hideElemnents = () => {
    setPrepareShare(true)
  }

  useEffect(() => {
    if (prepareShare) {
      captureImage();
    }
  }, [prepareShare])

  const captureImage = () => {
    if (contentRef.current) {
      html2canvas(contentRef.current, {
        ignoreElements: (element) => {        
          return element.classList.contains('share-hidden') 
        },
        backgroundColor: null
      })
      .then((canvas) => {
        const backgroundCanvas = document.createElement('canvas');
        const context = backgroundCanvas.getContext('2d');

        // Set the size of the background canvas
        backgroundCanvas.width = canvas.width;
        backgroundCanvas.height = canvas.height;
        const backgroundImage = new Image();
        backgroundImage.src = "/ShareHeader.webp"

        backgroundImage.onload = () => {
          const aspectRatio = backgroundImage.width / backgroundImage.height;
          const canvasWidth = backgroundCanvas.width;
          const canvasHeight = canvasWidth / aspectRatio;
          
          context.fillStyle = 'white';
          context.fillRect(0, 0, canvas.width, canvas.height);
          context.drawImage(backgroundImage, 0, 0, canvasWidth, canvasHeight);

          // Draw the captured content onto the background canvas
          context.drawImage(canvas, 0, 0);

          const imgData = backgroundCanvas.toDataURL('image/png');

          // Create a temporary <a> element to initiate the download
          const a = document.createElement('a');
          a.href = imgData;
          a.download = 'DiscGolfTraining-'+ formatDateTimeToYYYYMMDDHHMMSS() +'.png'; // Specify the filename
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a); 
        }
      })
      .catch((error) => {
        console.error('Error capturing image:', error);
      })
      .finally(() => {
        setPrepareShare(false);
      });
    }
  }

  return (
    iconOnly ?
    <Button variant="link" className="my-2" onClick={hideElemnents}><Download /></Button>
    :
    <Button variant="outline-success" className="m-2" onClick={hideElemnents}><Download /> Download Stats</Button>
  )

}

export default ShareResult;