import { Container, Spinner, Row, Col, Carousel } from "react-bootstrap";
import { useTitle } from "../DiscGolfTraining";
import { AuthState } from "@aws-amplify/ui-components";


export const C1PuttingDesc = (props) => {  
  useTitle("C1Putting Description");

  return (
    <Container>
      {
        !props.authState || props.authState === AuthState.SignIn ? 
          <Row>
            <Col md={6} lg={8}>
              <h1 className="py-2 text-primary">
                C1 Putting
              </h1>
              <h2 className="display-6">About:</h2>
              <p className="fs-6 text-justify">
                Use <b><i>C1 Putting</i></b> for training various C1x distances when you already have some confidence in your 
                circle one putting. The concept of this drill will position you based on your skill, e.g. less precise disc golfers 
                will be mostly throwing from the markings closer to the basket, while more profficient putters will end up practicing their 
                long C1 putts.
              </p>
              {
                props.authState !== AuthState.SignedIn &&
                <p><a href={props.LOGIN_URL} ><strong>Sign in</strong></a>&nbsp;and start throwing them discs into the basket.</p>
              }
              
              <h2 className="display-6">Setup:</h2>
              <div><span className="badge bg-primary">5 discs, 6 markers</span></div>
              <p>Mark positions on 5m, 6m, 7m, 8m, 9m and 10m from the basket. The training consists of 20 rounds, each 5 putts.
      After this training you'll make exactly 100 putts inside C1 (10m). Your starting position is on the 10m marker.
      After throwing 5 discs, enter the number of made putts into the application and your next position will be
      determined based on the following rule:</p>
              <ul>
              <li>0 hits -&gt; 5m</li>
              <li>1 hit -&gt; 6m</li>
              <li>2 hits -&gt; 7m</li>
              <li>3 hits -&gt; 8m</li>
              <li>4 hits -&gt; 9m</li>
              <li>5 hits -&gt; 10m</li>
              </ul>
              <p><strong>Scoring:</strong> For each round your score is &lt;# successful puts&gt;*&lt;distance coefficient&gt;.</p>
              <ul>
              <li>5m -&gt; 5pts</li>
              <li>6m -&gt; 6pts</li>
              <li>7m -&gt; 7pts</li>
              <li>8m -&gt; 8pts</li>
              <li>9m  -&gt; 9pts</li>
              <li>10m -&gt; 10pts</li>
              </ul>       
            </Col>
            <Col md={6} lg={4}>
              <Carousel variant="dark" interval={2500} className="bg-primary py-3 d-print-none d-block mh-100 ">
                <Carousel.Item className="text-center">
                  <img
                    className=""
                    style={{"height": "30rem"}}
                    src="/C1xPutting.webp"
                    alt="C1 Putting game"
                  />
                </Carousel.Item>      
                <Carousel.Item className="text-center">
                  <img
                    className=""
                    style={{"height": "30rem"}}
                    src="/Stats.webp"
                    alt="Statistics"
                  />
                </Carousel.Item>  
                <Carousel.Item className="text-center">
                  <img
                    className=""
                    style={{"height": "30rem"}}
                    src="/Leaderboard.webp"
                    alt="Leaderboard"
                  />
                </Carousel.Item>    
              </Carousel>
            </Col>  
          </Row>
          :
          <div className="text-center">
            <Spinner animation="border" variant="primary" role="status">
              <span className="sr-only"></span>
            </Spinner>
          </div>
      }
    </Container>
  )
}

export const FalloutDesc = (props) => {  
  useTitle("Fallout Description");

  return (
    <Container>
    {
      !props.authState || props.authState === AuthState.SignIn ? 
        <Row>
          <Col>
            <h1 className="py-2 text-primary">
              Fallout
            </h1>
            <h2 className="display-6">About:</h2>
            <p>
              The more you play, more pressure added. <b><i>Fallout</i></b> drill forces you to concentrate and hit easy putts to accumulate
              extra discs for the later phase and farther putts. The more you progress, you're more awarded for your hits. Remember: you can 
              only miss 5 putts until the game is over.
            </p>
            {
              props.authState !== AuthState.SignedIn &&
              <p><a href={props.LOGIN_URL} ><strong>Sign in</strong></a>&nbsp;and start throwing them discs into the basket.</p>
            }
            <h2 className="display-6">Setup:</h2>
            <div><span className="badge bg-primary">5 discs, 6 markers</span></div>
            <p>Mark positions on 5m, 6m, 7m, 8m, 9m and 10m from the basket. Start on 5m marker with 5 discs. Go to the next position only with the discs you made putts.</p>
            <p><strong>Scoring:</strong> Each distance has a distance coefficient which is multiplied by the number of made putts:</p>
            <ul>
            <li>5m, 6m -&gt; 1pt</li>
            <li>7m, 8m -&gt; 2pts</li>
            <li>9m, 10m  -&gt; 3pts</li>
            </ul>
          </Col>
        </Row>
      :
      <div className="text-center">
        <Spinner animation="border" variant="primary" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    }
    </Container>
  )
}

export const ThreePuttDesc = (props) => {  
  useTitle("Three Putt Description");

  return (
    <Container>
    {
      !props.authState || props.authState === AuthState.SignIn ?
        <Row>
          <Col>
          <h1 className="py-2 text-primary">
            Three putt
          </h1>
          <h2 className="display-6">About:</h2>
          <p>
            <b><i>Three putt</i></b> drill puts some extra pressure to your putting game. This will put a tournament-like feeling where you don't 
            want to miss a putt. In this drill you have only 3 discs in your hand and you need to make them all to proceed to the next distance. 
            Furthermore, if you miss two, you'll be penalized to move forward and with the all missed you move to the very first position (5m/16.5ft).
          </p>
          {
            props.authState !== AuthState.SignedIn &&
            <p><a href={props.LOGIN_URL} ><strong>Sign in</strong></a>&nbsp;and start throwing them discs into the basket.</p>
          }
          <h2 className="display-6">Setup:</h2>
          <div><span className="badge bg-primary">3 discs, 6 markers</span></div>
          <p>Mark positions on 5m, 6m, 7m, 8m, 9m and 10m from the basket. Start on 5m marker with 3 discs. If you make:</p>
          <ul>
            <li>3 putts - move one marker back</li>
            <li>2 putts - stay on the same marker</li>
            <li>1 putt - move one marker closer to the basket</li>
            <li>0 putts - go to the 5m marker.</li>
          </ul>
          <p>Can you get to the last marker?</p>
          </Col>
        </Row>
      : 
      <div className="text-center">
        <Spinner animation="border" variant="primary" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    }
    </Container>
  )
}


export const FreePracticeDesc = (props) => {
  useTitle("Free Practice Description");

  return (
    <Container>
    {
      !props.authState || props.authState === AuthState.SignIn ?
        <Row>
          <Col>
            <h1 className="py-2 text-primary">
              Free Practice
            </h1>
            <h2 className="display-6">About:</h2>
            <p className="fs-6 text-justify">
              <b><i>Free Practice</i></b> is recommended for all disc golfers especially ones that are not fully confident in their C1x putting.
              Start from short markers and try to hit your preset goal, e.g. <i className="text-muted">After 50 attempts, I want to have more than 40 putts made. </i> 
              Once you reach the goal, proceed to the next distance. 
            </p>
            {
              props.authState !== AuthState.SignedIn &&
              <p><a href={props.LOGIN_URL} ><strong>Sign in</strong></a>&nbsp;and start throwing them discs into the basket.</p>
            }
            <h2 className="display-6">Setup:</h2>
            <div><span className="badge bg-primary">1-15 discs, 1 or more markers</span></div>
            <p>Free training. Select number of discs and distance from the dropdowns and start your training. Enter the number of <strong>made putts</strong> into the application and continue from the same distance, or pick a new distance from the dropdown.</p>
            <p>After you're done, save your game.</p>
          </Col>
        </Row>
      :
      <div className="text-center">
        <Spinner animation="border" variant="primary" role="status">
          <span className="sr-only"></span>
        </Spinner>
      </div>
    }
    </Container>
  )
}