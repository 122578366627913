import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Container, Row, Col, Button, Spinner, Form } from "react-bootstrap";
import {API} from "aws-amplify";
import { useTitle } from "../DiscGolfTraining";
import DataTable from "react-data-table-component";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CHART_THEME } from "../static/constants";
import _ from 'lodash';
import qs from 'qs';


const Leaderboard = (props) => {
  useTitle(() => {
    switch (props.t_id) {
      case "C1P": 
        return "C1x Putting Leaderboard";
      case "FLU": 
        return "Fallout Leaderboard";
      case "C2P":
        return "C2 Leaderboard";
      default:
        return "Leaderboard";
    }
  })

  const ENTRIES_PER_PAGE = 100;
  const leaderboardStyle = {
    headCells: {
        style: {
            paddingLeft: '4px', 
            paddingRight: '4px',
        },
    },
    cells: {
        style: {
            paddingLeft: '4px', 
            paddingRight: '4px',
        },
    },
  };
  const columns = [
    {
      name: '#',
      selector: (row, index) => (leaderboardPage-1)*ENTRIES_PER_PAGE + index+1,
      width: "32px",
      minWidth: "32px"
    },
    {
      name: 'Name',
      selector: row => row.leaderboard_name,
      minWidth: "144px"
    },
    {
      name: 'Country',
      selector: row => row.country,
      minWidth: "44px"
    },
    {
      name: 'Date',
      selector: row => row.date,
      minWidth: "80px"
    },
    {
      name: 'Score',
      selector: row => row.score,
      right: true,
      minWidth: "36px"
    }
  ];

  // Leaderboard year filter
  const currentYear = new Date().getFullYear();
  const startYear = 2021;
  const yearOptions = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearOptions.push(
      <option key={year} value={year}>
        {year}
      </option>
    );
  }
  // Adding the default option
  yearOptions.unshift(
    <option key="default" value="---">
      -- Year --
    </option>
  );

  const countries = {
    "AT": "Austria",
    "BE": "Belgium",
    "CA": "Canada",
    "HR": "Croatia",
    "FI": "Finland",
    "DE": "Germany",
    "IS": "Iceland",
    "IT": "Italy",
    "RS": "Serbia",
    "SI": "Slovenia",
    "ES": "Spain",
    "SE": "Sweden",
    "US": "United States",
    "--": "----------------",
    "AF": "Afghanistan",
    "AX": "Aland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CV": "Cape Verde",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo, Democratic Republic of the Congo",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Cote D'Ivoire",
    "CU": "Cuba",
    "CW": "Curacao",
    "CY": "Cyprus",
    "CZ": "Czech Republic",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and Mcdonald Islands",
    "VA": "Holy See (Vatican City State)",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran, Islamic Republic of",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea, Democratic People's Republic of",
    "KR": "Korea, Republic of",
    "XK": "Kosovo",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libyan Arab Jamahiriya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "North Macedonia",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia, Federated States of",
    "MD": "Moldova, Republic of",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "AN": "Netherlands Antilles",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestinian Territory, Occupied",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Reunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthelemy",
    "SH": "Saint Helena",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "CS": "Serbia and Montenegro",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten",
    "SK": "Slovakia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Viet Nam",
    "VG": "Virgin Islands, British",
    "VI": "Virgin Islands, U.s.",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe",
    "N/A": "Undefined"
  }

  const conditionalRowStyles = [
    {
      when: row => row.ID === ("PLAYER#"+props.user.id),
      style: {
        fontWeight: "bold"
      },
    }
  ]
  
  const { search } = useLocation();
  const history = useHistory();
  const [leaderboard, setLeaderboard] = useState([]);
  const [leaderboardTitle, setLeaderboardTitle] = useState(() => {
    switch (props.t_id) {
      case "C1P": 
        return "C1x Putting Leaderboard";
      case "FLU": 
        return "Fallout Leaderboard";
      case "C2P":
        return "C2 Leaderboard";
      default:
        return "Leaderboard";
    }
  });
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(true)
  const [totalRows, setTotalRows] = useState(0)
  const [leaderboardPage, setLeaderboardPage] = useState(1)
  const [pagination, setPagination] = useState([{token: null, rows: 0}])
  const [trainingLink, setTrainingLink] = useState(props.t_id)

  const [filter, setFilter] = useState(() => {
    if (search) {
      return qs.parse(search, { ignoreQueryPrefix: true })
    }
    return {}
  })

  const ExpandedComponent = ({ data }) => {
    let playerStats = _.pick(data, '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15');
    return (
      <Row className="mb-4">
        { 
          Object.entries(playerStats).map(([dst, stats]) => (
            <Col xs={6} md={4} key={dst} className="mb-3">
              <Pie 
                data={{
                  labels: ["Hit", "Miss"],
                  datasets: [
                    {
                      label: {dst},
                      data: [parseInt(stats.hit), parseInt(stats.total) - parseInt(stats.hit)],
                      backgroundColor: CHART_THEME
                    }
                  ],
                }}
                options={{ 
                  maintainAspectRatio: false, 
                  plugins: { 
                    title: {display: true, text: `${dst}m | ${stats.total>0?(100*(stats.hit/stats.total)).toFixed(2):0}%` }, 
                    subtitle: {display: true, text: `(${stats.hit}/${stats.total})`},
                    datalabels: {
                      color: '#FFF',
                      anchor: 'center'
                    }
                  }
                }}
                plugins={[ChartDataLabels]}
                 
              />
            </Col>
          ))
        }
      </Row>
    )
  }

  const fetchLeaderboard = page => {
    let pageData = pagination[page-1]
    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken},
      response: true,
    }
    if (Object.keys(filter).length > 0) requestData.queryStringParameters = filter

    if (pageData.token) requestData.headers['next_token'] = pageData.token

    API.get("DGTAPI", "/training/"+props.t_id+"/leaderboard", requestData)
      .then(response => { 
        let newPagination = pagination
        newPagination[page-1].rows = response.data.data.length
        
        if (response.headers.next_token && !pagination[page]) { // add a new page
          newPagination = [...pagination, {token: response.headers.next_token, rows: 0}]
        } 

        if (!response.headers.next_token && pagination.length>page) {
          newPagination = pagination.slice(0, page)
        }

        let totalRows = 0;
        newPagination.forEach((page) => {
          totalRows+=page.rows
        })

        setTotalRows(newPagination[newPagination.length-1].rows===0?totalRows+'+':totalRows)
        setPagination(newPagination)
        setLeaderboard(response.data.data)
        setLoadingLeaderboard(false)
        setLeaderboardPage(page)
        setLeaderboardTitle(response.data.metadata.attributes?.title + " Leaderboard")
        setTrainingLink(response.data.metadata.t_id)
        
      })
      .catch(error => {
        props.setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't load leaderboard."})
        setLoadingLeaderboard(false)
      })
  }

  const onPageChange = page => {
  		fetchLeaderboard(page);      
  };

  useEffect(() => {
    fetchLeaderboard(1)
  }, [filter])

  return (
    <Container>
      <Row className="display-6 mb-4">
        <Col md={8}>{leaderboardTitle} </Col>
        <Col md={4} className="text-end"><Button variant="primary" href={"/trainings/"+trainingLink}>Start new game</Button></Col>
      </Row>
      <Row className="d-flex flex-row-reverse">
        {/* <Col xs={1} className="text-end">
          <Button variant="warning" size="sm" className="d-block" onClick={(e) => {
            setFilter({})
            history.replace(`/trainings/${props.t_id}/leaderboard`)
          }}>Reset</Button>
        </Col> */}
        <Col xs="auto" >
          <Form.Label>Year</Form.Label>
          <Form.Select
            aria-label="Select Year" 
            name="year"
            value={filter.year}
            size="sm"
            onChange={(e) => {
              let val = e.target.value
              let newFilter = filter
              if (val === "---") {
                const { ["year"]: tmp, ...rest } = filter;
                newFilter = rest
                setFilter(rest)
                
              } else {
                newFilter = { ...filter, "year": val}
                setFilter(newFilter)
              }
              history.replace(`/trainings/${props.t_id}/leaderboard?${qs.stringify(newFilter)}`)
            }}
          >
            {yearOptions}
          </Form.Select>
        </Col>
        <Col xs={6} sm={5} md={4} lg={3} >
          <Form.Group className="mb-3" controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Select 
              aria-label="Select Country" 
              name="country"
              value={filter.country}
              size="sm"
              onChange={(e) => {
                let val = e.target.value
                let newFilter = filter
                if (val === "---") {
                  const { ["country"]: tmp, ...rest } = filter;
                  newFilter = rest
                  setFilter(rest)
                  
                } else {
                  newFilter = { ...filter, "country": val}
                  setFilter(newFilter)
                }
                history.replace(`/trainings/${props.t_id}/leaderboard?${qs.stringify(newFilter)}`)
              }}
            >
              <option value="---">-- Select country --</option>
              {
                
                props?.profile?.address &&
                <option key={props?.profile?.address} value={props?.profile?.address}>{countries[props?.profile?.address]} (You)</option>
              }
              {
                Object.entries(countries).map(([code, name]) => {
                  if (code === props?.profile?.address) return;
                  if (code === "--"){
                    return (
                      <option disabled>----------------</option>
                    )
                  } else {
                    return (
                      <option key={code} value={code}>{name}</option>
                    )
                  }
                })
              }
              { /* <option value="---">-- Select country --</option>
              <option value="AT">Austria</option>
              <option value="BE">Belgium</option>
              <option value="CA">Canada</option>
              <option value="HR">Croatia</option>
              <option value="FI">Finland</option>
              <option value="DE">Germany</option>
              <option value="IS">Iceland</option>
              <option value="IT">Italy</option>
              <option value="RS">Serbia</option>
              <option value="SI">Slovenia</option>
              <option value="ES">Spain</option>
              <option value="SE">Sweden</option>
              <option value="US">United States</option>
              <option disabled>----------------</option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Aland Islands</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet Island</option>
              <option value="BR">Brazil</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CX">Christmas Island</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CD">Congo, Democratic Republic of the Congo</option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Cote D'Ivoire</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curacao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="TF">French Southern Territories</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HM">Heard Island and Mcdonald Islands</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IL">Israel</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KP">Korea, Democratic People's Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="XK">Kosovo</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Lao People's Democratic Republic</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libyan Arab Jamahiriya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macao</option>
              <option value="MK">North Macedonia</option>
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia, Federated States of</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="AN">Netherlands Antilles</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestinian Territory, Occupied</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PN">Pitcairn</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Reunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint Barthelemy</option>
              <option value="SH">Saint Helena</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="MF">Saint Martin</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="CS">Serbia and Montenegro</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SX">Sint Maarten</option>
              <option value="SK">Slovakia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="GS">South Georgia and the South Sandwich Islands</option>
              <option value="SS">South Sudan</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SZ">Swaziland</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="TW">Taiwan, Province of China</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela</option>
              <option value="VN">Viet Nam</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VI">Virgin Islands, U.s.</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="EH">Western Sahara</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
              <option value="N/A">Undefined</option> */}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
      
       <Row>
        <DataTable
          columns={columns}
          data={leaderboard}
          conditionalRowStyles={conditionalRowStyles}
          customStyles={leaderboardStyle}
          // noDataComponent="Loading..."
          progressPending={loadingLeaderboard}
          progressComponent={<Spinner animation="border" variant="primary" />}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandOnRowClicked
          expandableRowsHideExpander
          persistTableHead
          dense
          pagination
			    paginationServer
          onChangePage={onPageChange}
			    paginationTotalRows={totalRows}
          paginationIconFirstPage={null}
          paginationIconLastPage={null}
          paginationPerPage={ENTRIES_PER_PAGE}
          paginationRowsPerPageOptions={[ENTRIES_PER_PAGE]}
          paginationComponentOptions={{
            noRowsPerPage: true
          }}
        />
      </Row>      
    </Container>
  )  
}

export default Leaderboard
