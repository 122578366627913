import { Container, Alert } from "react-bootstrap";
import { WhatDGT, WhoDGT, Features } from "../components/DGTInfo";
import ShareCanvas from "../components/ShareCanvas";
import { Footer } from "../components/DGTFooter";
import { useTitle } from "../DiscGolfTraining";
import { useState } from 'react';


export const About = (props) => {
  useTitle("About");

  const [show, setShow] = useState(true);

  return (
    <>
    <Container>
      <ShareCanvas />
      <Alert variant="info" onClose={() => setShow(false)} dismissible>
        <Alert.Heading>Facebook Login Shutdown</Alert.Heading>
        <p>
          I'm sorry to inform you that due to the changes in Facebook's policy, I need to remove Facebook login option starting Jul 5th, 2024.
        </p>
        <p>
          If you used Facebook as a login provider, your data WILL NOT be lost. 
        </p>
        <p>
        How to preserve your trainings:
        <ul>
          <li><b>Option 1:</b> If you can use the same email address associated with your Facebook account to login via username/password or Google provider your data will be connected automatically.</li>
          <li><b>Option 2:</b> If you have to switch the login email address - reach out to my email address (admin@discgolf.training), noting both old and new email addresses, and I'll be able to migrate all data to your new account.</li>
        </ul>
        </p>
        <p>
          Sorry for the inconvenience and keep training!
        </p>
      </Alert>
      <WhatDGT authState={props.authState} user={props.user} LOGIN_URL={props.LOGIN_URL}/>
      
      {/* <WhyDGT className="text-end"/> */}
      <Features authState={props.authState} LOGIN_URL={props.LOGIN_URL}/>
      <WhoDGT />
      {/* <Testimonials /> */}
    </Container>
    <Footer />
    </>
  )
}