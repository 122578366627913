import { Navbar, Nav, NavDropdown, Container, Button, Badge, Offcanvas, Card, Figure } from "react-bootstrap";
import { AuthState } from '@aws-amplify/ui-components';
import { Auth, Hub, API } from "aws-amplify";
import { Inbox, CircleFill } from "react-bootstrap-icons";
import { useState } from "react";
import ReactMarkdown from "react-markdown";
import { useEffect } from "react";


export const DGTNavbar = (props) => {
  const [showShare, setShowShare] = useState(false);
  const [unread, setUnread] = useState(0);
  const [announcements, setAnnouncements] = useState([])

  const handleClose = () => {
    setShowShare(false);
    setUnread(0);
    markAllAsRead();
  }
  const handleShow = () => setShowShare(true);

  const markAllAsRead = () => {
    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken},
      body: announcements
    }

    API.post("DGTAPI", "/announcements/read", requestData)
      .then(response => {
      })
      .catch(error => {
        console.error("Couldnt fetch announcements.")
      })
    for (let i = 0; i < announcements.length; i++) {
      announcements[i].read = true;
    }
    setAnnouncements(announcements);
  }

  useEffect(() => {
    if (props.user) {
      let requestData = {
        headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken}
      }
  
      API.get("DGTAPI", "/announcements", requestData)
        .then(response => {
          setAnnouncements(response);
          let resp_unread=response.length;
          for (let index = 0; index < response.length; index++) {
            if(response[index]['read']) resp_unread-=1;
            
          }
          setUnread(resp_unread); 
        })
        .catch(error => {
          console.error("Couldnt fetch announcements.")
        })
    }   
  }, [props.user])

  return (
    <>
      <Figure className="d-none d-print-block">
        <Figure.Image 
          src="/ShareImage.webp"
          className="align-middle"
        />
      </Figure>

      <Navbar bg="nav gradient" variant="dark" expand="md" fixed="top" className="py-2 d-print-none">
        <Container>
          
          <Navbar.Brand href="/">
          <img
            alt=""
            src="/logo64_white.webp"
            width="30"
            height="30"
            className="d-inline-block align-top"
          />{' '}Disc Golf Training</Navbar.Brand>
          
          
          
          
          <Navbar.Collapse id="main-navbar-nav">
            {
              (props && (!props.authState || props.authState === AuthState.SignIn || props.authState === AuthState.SignedOut)) &&
              <>
              <Nav className="me-auto">
                <Nav.Link href="/games">Games &amp; Rules</Nav.Link>
              </Nav>
              <Nav > 
                <Navbar.Text>
                  <a href={props.LOGIN_URL}>Sign in</a>
                </Navbar.Text>
              </Nav>
              </>
            }
            {
              props.authState === AuthState.SignedIn && props.user &&
              <>
                <Nav className="me-auto">
                  <Nav.Link href="/">Dashboard</Nav.Link>
                  <NavDropdown title="Trainings" id="basic-nav-dropdown">
                    <NavDropdown.ItemText className="text-primary text-center">C1 Putting</NavDropdown.ItemText>
                    <NavDropdown.Item href="/trainings/C1P">Start Training</NavDropdown.Item>
                    <NavDropdown.Item href="/trainings/C1P/leaderboard">Leaderboard</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.ItemText className="text-primary text-center">C2 Putting</NavDropdown.ItemText>
                    <NavDropdown.Item href="/trainings/C2P">Start Training</NavDropdown.Item>
                    <NavDropdown.Item href="/trainings/C2P/leaderboard">Leaderboard</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.ItemText className="text-primary text-center">Fallout</NavDropdown.ItemText>
                    <NavDropdown.Item href="/trainings/FLU">Start Training</NavDropdown.Item>
                    <NavDropdown.Item href="/trainings/FLU/leaderboard">Leaderboard</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.ItemText className="text-primary text-center">Three Putt</NavDropdown.ItemText>
                    <NavDropdown.Item href="/trainings/TPT">Start Training</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.ItemText className="text-primary text-center">Free Practice</NavDropdown.ItemText>
                    <NavDropdown.Item href="/trainings/FP">Start Training</NavDropdown.Item>
                    {
                      props.profile?.private_leaderboards && props.profile?.private_leaderboards.length>0?
                      props.profile?.private_leaderboards.map((leaderboard, idx) => {
                        
                        const parsedLeaderboard = JSON.parse(leaderboard);
                        return (
                          <>
                            <NavDropdown.Divider />
                            <NavDropdown.ItemText className="text-primary text-center">{parsedLeaderboard.code}</NavDropdown.ItemText>
                            <NavDropdown.Item href={"/trainings/"+parsedLeaderboard.t_id}>Start Training</NavDropdown.Item>
                            <NavDropdown.Item href={"/trainings/"+parsedLeaderboard.code+"/leaderboard"}>Leaderboard</NavDropdown.Item>
                          </>
                        )})
                      : <></>
                    }
                  </NavDropdown>
                  <Nav.Link href="/trainings">Rules</Nav.Link>
                  <Nav.Link href="/about">About</Nav.Link>
                </Nav>
                <Nav>
                  <NavDropdown title={<strong> {props.displayName} </strong>} className="text-secondary" align="end">
                    <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                    <NavDropdown.Item href="/">Statistics</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item className="d-grid gap-2">
                    {/* <AmplifySignOut height="0.5rem"/> */}
                      <CustomSignOutButton />
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </>
            }
            
          </ Navbar.Collapse>
          {
            props.authState === AuthState.SignedIn && props.user &&
            <Nav className="justify-content-end ms-auto ms-md-1 me-3">
              <Button variant="link" onClick={handleShow} className="text-decoration-none position-relative">
                <Inbox size={24} color="#FFF"/> 
                { unread>0 &&
                  <>
                    <small><Badge bg="danger" className="position-absolute top-0 end-0">{unread}</Badge></small>
                    <span className="visually-hidden">new announcements</span>
                  </>
                }
              </Button>  
            </Nav>
          }
          <Navbar.Toggle aria-controls="main-navbar-nav" className="justify-content-end"/>
        
          
        </Container>
      </Navbar> 

      <Offcanvas show={showShare} onHide={handleClose} placement="end" backdrop={false}>
      <Offcanvas.Header closeButton variant="primary">
        <Offcanvas.Title><Inbox size={24} />&nbsp;Announcements</Offcanvas.Title>
      </Offcanvas.Header>
      <hr className="m-0"/>
      <Offcanvas.Body className="d-flex flex-column-reverse justify-content-end">  
        {
          announcements && announcements.length>0?
            announcements.map((announcement, idx) => (
              <Card className="mb-3" key={idx} border={!announcement.read?"primary":null} >
                <Card.Header className="text-muted justify-content-end" >
                  {!announcement.read?<CircleFill size={8} color="var(--bs-primary)" />:null}
                  &nbsp;{announcement.Type}
                </Card.Header>
                <Card.Body>
                  <Card.Title>{announcement.title}</Card.Title>
                  <Card.Text as="span">
                    <ReactMarkdown children={announcement.description} />
                  </Card.Text>
                </Card.Body>
                
              </Card>
            ))
          :
            <div className="text-muted text-center">You're up-to-date...</div>
        }
      </Offcanvas.Body>
    </Offcanvas>


  </>
  )
}
export default DGTNavbar;


const handleSignOutButtonClick = async () => {
  try {
      await Auth.signOut();
      Hub.dispatch('UI Auth', {   // channel must be 'UI Auth'
          event: 'AuthStateChange',    // event must be 'AuthStateChange'
          message: 'signedout'    // message must be 'signedout'
      });
  } catch (error) {
      console.log('error signing out: ', error);
  }
};  

const CustomSignOutButton = () => {
  return (
      <Button
        variant="link"
        size="sm"
        onClick={handleSignOutButtonClick}
      >
        Sign out
      </Button>
  );
};