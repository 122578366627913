
import React from 'react'
import { Container, Row, Col, Card, Button, Badge, Placeholder, Form } from 'react-bootstrap'
import { useEffect, useState } from 'react';
import { API } from 'aws-amplify'
import ReactMarkdown from 'react-markdown';
import { Clock } from "react-bootstrap-icons";

export const TrainingList = (props) => {

  const [trainings, setTrainings] = useState([]);
  const [leaderboardCode, setLeaderboardCode] = useState()

  useEffect(() => {
    if (props.user && props.user.signInUserSession) {

      let requestData = {
        headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken}
      }   
      if (props.profile?.private_leaderboards) {
        let private_leaderboards = props.profile?.private_leaderboards.map((jsonString) => {
          const obj = JSON.parse(jsonString);
          return obj.code;
        });
        requestData.queryStringParameters = {
          private_leaderboards
        }
      }
      
      API.get("DGTAPI", '/training', requestData)
        .then(response => {
          setTrainings(response)
        }).catch(error => {
          console.log(error.response)
        });
    }
  }, [props.profile, props.user])

  const submitCode = (e) => {
    e.preventDefault()

    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken},
      body: {code: leaderboardCode}
    }

    API.post("DGTAPI", "/training/code", requestData)
      .then(response => {
        setTrainings(response)
      }).catch(() => {
        props.setAlert({
          variant: "danger",
          show: true,
          dismissible: true,
          message: "Invalid code."
        })
      }).finally(() => {
        setLeaderboardCode('')
      })
  };

  return (
    <Container className="mt-2 mb-4 share-hidden">
      <Row className="mb-2">
        <h1 className="display-6">Start Training</h1>
      </Row>
      <Row xs={1} sm={2} lg={4} className="g-2">
      { 
      trainings.length===0?
        <>
        <Card className="h-100">
          <Placeholder as={Card.Title} animation="glow" ><Placeholder xs={6} bg="primary"/></Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> <Placeholder xs={8} />
          </Placeholder>
          <Placeholder as={Card.Footer} animation="glow" className="text-end">
            <Placeholder.Button variant="primary" xs={6} />
          </Placeholder>
        </Card>
        <Card className="h-100">
          <Placeholder as={Card.Title} animation="glow" ><Placeholder xs={6} bg="primary"/></Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
            <Placeholder xs={6} /> <Placeholder xs={8} />
          </Placeholder>
          <Placeholder as={Card.Footer} animation="glow" className="text-end">
            <Placeholder.Button variant="primary" xs={6} />
          </Placeholder>
        </Card>
        </>
      :
        trainings.map((training) => (
        <Col key={training.t_id}>
          <Card key={training.t_id} className="h-100">
            {/* <Card.Img variant="top" src="holder.js/100px160" /> */}
            <Card.Body>
              <Card.Title>
              { training.draft?
                <span className="text-muted">{training.title}</span>
              :
                <a href={"/trainings/"+training.t_id} className="text-decoration-none">{training.title}</a>
              }
              {
              training.ID === "PRIVATE" ?
                <sup>&nbsp;<Badge pill bg="primary">private</Badge></sup>
              :
                <span>&nbsp;</span>
              }
              {
              training.leaderboard?
                <sup>&nbsp;<Badge pill bg="primary">+leaderboard</Badge></sup>
              :
                <span>&nbsp;</span>
              }
              </Card.Title>
              <Card.Text as={Row} >
                { training.equipment && training.equipment_desc?
                    <abbr title={training.equipment_desc}>{training.equipment}</abbr>
                  :
                    <small>&nbsp;</small>  
                }
                <ReactMarkdown children={training.short_description} />
              </Card.Text>
            </Card.Body>
            <Card.Footer className="d-flex align-items-center">
              <Col className="text-start text-muted">
                { training.duration &&
                  <span className="align-middle"><Clock />&nbsp;{training.duration}</span>
                }
                
              </Col>
              <Col className="text-end">
                { training.draft?
                      <Button href={"/trainings/"+training.t_id} disabled={true}>Coming soon...</Button>
                    :
                      <Button href={"/trainings/"+training.t_id}>Start Training</Button>
                }
              </Col>
            </Card.Footer>
          </Card>
        </Col>
      ))
      
      
      }
       <Col key="new">
          <Card key="new" className="h-100">
            <Card.Body>
              <Card.Title className="text-dark">
                Have a code?
              </Card.Title>
              <Card.Text as={Col} className="text-muted">
                <p>Start competing with your friends in a private leaderboard. </p>
              </Card.Text>
            </Card.Body>
            <Card.Footer className="">
              <Form onSubmit={(e) => submitCode(e)}>
                <Row>
                <Col className="text-start text-muted">
                  <Form.Control
                    type="text"
                    id="leaderboardCode"
                    name="leaderboardCode"
                    placeholder="Code"
                    defaultValue={leaderboardCode}
                    value={leaderboardCode}
                    onChange={(e) => setLeaderboardCode(e.target.value)}
                  />
                  
                </Col>
                <Col className="text-end">
                  { 
                    <Button className='btn-dark' type="submit" variant="primary">Submit</Button>
                  }
                </Col>
                </Row>
              </Form>
            </Card.Footer>
          </Card>
        </Col> 
        <Col key="request">
          <Card key="request" className="h-100">
            <Card.Body>
              <Card.Title className="text-dark">
                Create a private leaderboard
              </Card.Title>
              <Card.Text as={Col} className="text-muted">
                Get in touch and we'll make a deal!
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button className='btn-dark' href="mailto:admin@discgolf.training?subject=Private leaderboard enquiry">Make request</Button>
            </Card.Footer>
          </Card>
        </Col> 
      </Row>
    </Container>
  )
}
