import React, { useState, useEffect, useRef } from "react";
import { Button, Container, Row, Col, Accordion, ProgressBar, Figure } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { API } from "aws-amplify";
import { ConfirmationModal } from "./Modals";
import { PercentageTable } from "./PercentageTable";
import ShareResult from "./ShareResult";


const C2Putting = ({prepareShare, setPrepareShare, ...props}) => {

  const TRAINING_ID = props.id || "C2P";
  const LOCALSTORAGE_PROGRESS = TRAINING_ID+"Progress";
  const NUMBER_OF_ROUNDS = 10;
  const startGame = {
    "round": 1,
    "rounds": [],
    "score": 0,
    "next": 15,
    "10": {
      "hit": 0,
      "total": 0
    },
    "11": {
      "hit": 0,
      "total": 0
    },
    "12": {
      "hit": 0,
      "total": 0
    },
    "13": {
      "hit": 0,
      "total": 0
    },
    "14": {
      "hit": 0,
      "total": 0
    },
    "15": {
      "hit": 0,
      "total": 0
    },
    "_lastRound": false
  }
  const distances = [10, 11, 12, 13, 14, 15];
  const hits = [0, 1, 2, 3, 4, 5];

  const [trainingProgress, settrainingProgress] = useState(() =>{
    let savedData = {}
    
    let stored = localStorage.getItem(LOCALSTORAGE_PROGRESS) 
    if (stored) {
      savedData = JSON.parse(Buffer.from(stored, "base64").toString("ascii"))[props.user.id] || {} 
    }
    
    return {...startGame, ...savedData}
  });

  const [trainingDetails, setTrainingDetails] = useState({description: "", equipment: ""});
  const [animate, setAnimate] = useState(false);
  const [showConfirmationModal, setshowConfirmationModal] = useState(false);

  const handleCloseModal = () => setshowConfirmationModal(false);
  const handleShowModal = () => setshowConfirmationModal(true);
  const handleConfirmation = () => {
    handleCloseModal();
    saveLocal({training_i: trainingProgress.training_i, ...startGame});
    settrainingProgress({training_i: trainingProgress.training_i, ...startGame});
  }

  const saveLocal = (progress) => {
    progress?
      localStorage.setItem(LOCALSTORAGE_PROGRESS, Buffer.from(JSON.stringify({[props.user.id]: progress})).toString("base64"))
      :
      localStorage.removeItem(LOCALSTORAGE_PROGRESS)
  };

  useEffect(() => {
    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken}
    }

    if (!("training_i" in trainingProgress)) {  
      API.post("DGTAPI", "/training/"+TRAINING_ID, requestData)
        .then(response => {
          settrainingProgress({training_i: response.training_i, ...trainingProgress})
          setTrainingDetails(response.attributes)
          saveLocal({training_i: response.training_i, ...trainingProgress});
        }).catch(() => {
          props.setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't initialize your training."})
        });
    } else {
      if (!trainingDetails.description || trainingDetails.description === ""){
        API.get("DGTAPI", "/training/"+TRAINING_ID, requestData)
        .then(response => {
          setTrainingDetails(response.attributes)
        }).catch(() => {
          props.setAlert({variant: "danger", show: true, dismissible: true, message: "Something went wrong. Couldn't initialize your training."})
        });
      }
    }
    
  }, [trainingProgress.training_i])


  const submitGame = () => {
    // alert("Submitting game...")
    let trainingResult = JSON.parse(JSON.stringify(trainingProgress))
    delete trainingResult._lastRound
    delete trainingResult.next
    delete trainingResult.round
    delete trainingResult.training_i


    let requestData = {
      headers: {Authorization: 'Bearer ' + props.user.signInUserSession.idToken.jwtToken},
      body: {...trainingResult}
    }

    API.post("DGTAPI", "/training/"+TRAINING_ID+"/"+trainingProgress.training_i, requestData)
      .then(() => {
        saveLocal(null)
        settrainingProgress(startGame)
        props.setAlert({variant: "success", show: true, dismissible: true, message: "Training successfully saved! <a href=\"/trainings/"+TRAINING_ID+"/leaderboard\" className=\"alert-link\">Show Leaderboard</a>."})
      }).catch(() => {
        props.setAlert({
          variant: "danger",
          show: true,
          dismissible: true,
          message: "Something went wrong. Refresh the page and save again, your data won't be lost."
        })
      })
  };

  const submitRound = (hit) => {
    let currentProgress = JSON.parse(JSON.stringify(trainingProgress))
    currentProgress["_lastRound"] = JSON.parse(JSON.stringify(trainingProgress))

    currentProgress["round"] = trainingProgress.round+1
    currentProgress["rounds"].push(hit)
    currentProgress["score"] = trainingProgress["score"] + (trainingProgress.next*hit)
    currentProgress["next"] = distances[hit]
    currentProgress[trainingProgress["next"]]["hit"] = trainingProgress[trainingProgress["next"]]["hit"] + parseInt(hit)
    currentProgress[trainingProgress["next"]]["total"] = trainingProgress[trainingProgress["next"]]["total"] + 5

    saveLocal(currentProgress)
    settrainingProgress(currentProgress)
    setAnimate(true)
      
  };

  const undoLast = () => {
    saveLocal(trainingProgress._lastRound)
    settrainingProgress(trainingProgress._lastRound)
  };

  const resetGame = () => handleShowModal();

  return (
    <div>
      <Container className="p-2">
        {trainingProgress.round>NUMBER_OF_ROUNDS?
        <Container>
          {prepareShare && 
            <Row className="text-center">
              <h2 className="display-4 text-white">C2 Putting</h2>
            </Row>
          }
          <Row className="text-center">
            <h3 className="display-6">Score: {trainingProgress.score}</h3>
          </Row>
          <Row className="justify-content-md-center share-hidden">
            <Col md={3} className="d-grid gap-6">
              <Button variant="success" className="m-2" onClick={() => submitGame()}>Save Game</Button>
              <ShareResult contentRef={props.shareRef} setPrepareShare={setPrepareShare} prepareShare={prepareShare}/>
            </Col>
          </Row>          
        </Container>
        :
        <Container>
          <Row>
            {/* <Accordion>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Container} variant="link" eventKey="0">
                    <h1 className="h3">C1 Putting</h1>
                    { trainingDetails.equipment && trainingDetails.equipment_desc && <small><abbr title={trainingDetails.equipment_desc}>{trainingDetails.equipment}</abbr></small> }
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <ReactMarkdown children={description} />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion> */}
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="d-grid gap-1">
                    <h1 className="h3">{trainingDetails.title} <small className="fs-6 fst-italic fw-light">Tap to expand the rules</small></h1>
                    { trainingDetails.equipment && trainingDetails.equipment_desc && <small><abbr title={trainingDetails.equipment_desc}>{trainingDetails.equipment}</abbr></small> }
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <ReactMarkdown children={trainingDetails.description} />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Row>
          <Row className="my-2 text-center fw-bold">
            <Col xs={4}>
              Round:
            </Col>
            <Col xs={4}>
              Position:
            </Col>
            <Col xs={4}>
              Score:
            </Col>
          </Row>
          <Row className="my-2 text-center align-items-center">
            <Col className={animate?'fadeIn':''} onAnimationEnd={() => setAnimate(false)}> 
              #{trainingProgress.round}
            </Col>
            <Col className={animate?'fadeIn fw-bolder fs-5':'fw-bolder fs-5'} onAnimationEnd={() => setAnimate(false)}>
              {trainingProgress.next}&nbsp;m&nbsp;/&nbsp;{(3.3*trainingProgress.next).toFixed(trainingProgress.next===10?0:1)}'
            </Col>
            <Col className={animate?'fadeIn':''} onAnimationEnd={() => setAnimate(false)}>
              {trainingProgress.score}
            </Col>   
          </Row>
        </Container>
        }
        <Row className="my-3">
          <ProgressBar now={trainingProgress.round - 1} min={0} max={NUMBER_OF_ROUNDS} className="p-0" style={{height: "2px"}}/>
        </Row>
        <Row className="share-hidden">
          <Col className="text-muted text-center">Putts hit:</Col>
        </Row>
        <Row className="share-hidden">
          { hits.map((hit) => (
            <Col lg={2}  xs={4} >
              <div className="d-grid gap-1">
                <Button variant="primary" key={hit} className="mx-1 my-2" disabled={trainingProgress.round>NUMBER_OF_ROUNDS} value={hit} onClick={(e) => submitRound(e.target.value)}>{hit}</Button>
              </div>
            </Col>
          ))}
        </Row>
        
        <Row className="mt-3">
          <PercentageTable trainingProgress={trainingProgress} distances={distances} user={props.user}/>
        </Row>
        <Row className="mt-3 share-hidden">
          <Col xs={6} className="">
            <div className="d-grid gap-1">
              <Button variant="danger" className="" disabled={!trainingProgress._lastRound || trainingProgress.round>NUMBER_OF_ROUNDS} onClick={() => resetGame()}>Reset Game</Button>
            </div>
          </Col>
          <Col xs={6}>
            <div className="d-grid gap-1">
              <Button variant="secondary" className="" disabled={!trainingProgress._lastRound} onClick={() => undoLast()}>Undo Last</Button>
            </div>
          </Col>
        </Row>
        <ConfirmationModal 
          show={showConfirmationModal} 
          handleClose={handleCloseModal} 
          handleConfirmation={handleConfirmation}
          title="Reset Game"
          body="You will lose data for this training. Are you sure?"
          variant="danger"
          buttonText="Reset"
        />
      </Container>
    </div>
  )
}

export default C2Putting